import { memoize } from "lodash";

import { COMPANY_SETUP_STEP_CONFIGS } from "services/doverapi/endpoints/company-setup/constants";
import {
  CompanySetupStepConfig,
  CompanySetupStepConstants,
  CompanySetupStepNameToInfo,
  EnrichedCompanySetupStep,
  OnboardingSection,
} from "services/doverapi/endpoints/company-setup/types";
import {
  ClientOnboarding,
  ClientOnboardingAtsTypeEnum,
  CompanySetupStep,
  CompanySetupSteps,
  CompanySetupStepStateEnum,
  CompanySetupStepStepNameEnum,
} from "services/openapi";
import { showErrorToast, showSuccessToast } from "utils/showToast";

interface SideEffectForClientOnboardingUpdatArgs {
  onboardingSection: OnboardingSection;
  response: ClientOnboarding;
}

export function performSideEffectForClientOnboardingUpdate({
  onboardingSection,
  response,
}: SideEffectForClientOnboardingUpdatArgs): void {
  switch (onboardingSection) {
    case OnboardingSection.ATS_SETTINGS:
      if (response.atsType === ClientOnboardingAtsTypeEnum.Greenhouse && !response.greenhouseCredsAreValid) {
        showErrorToast("Your Greenhouse credentials seem to be invalid.");
      } else if (response.atsType === ClientOnboardingAtsTypeEnum.Ashby && !response.ashbyCredsAreValid) {
        showErrorToast("Your Ashby API key seems to be invalid.");
      } else {
        showSuccessToast(`ATS settings saved.`);
      }
      return;
    case OnboardingSection.BRANDING:
      showSuccessToast(`Brand settings saved.`);
      return;
    case OnboardingSection.CAREERS_PAGE:
      showSuccessToast(`Careers Page Saved.`);
      return;
    case OnboardingSection.EXCLUSIONS:
      showSuccessToast(`Exclusions settings Saved.`);
      return;
    case OnboardingSection.BASIC_INFO:
      showSuccessToast("Basic info has been saved.");
      return;
    default:
      return;
  }
}

export const getCompanySetupStepMap = memoize(
  (companySetupStepStates: CompanySetupSteps): CompanySetupStepNameToInfo => {
    const map: any = {};
    companySetupStepStates.setupSteps.forEach((stepState: CompanySetupStep) => {
      map[stepState.stepName] = { state: stepState.state, isRelevantToJobs: stepState.isRelevantToJobs };
    });
    return map as CompanySetupStepNameToInfo;
  }
);

const getStepRoute = (stepPath: string): string => {
  return `/setup/company/${stepPath}`;
};

function getCompanySetupSteps({
  stepNameToInfoMap,
  disregardCompleteSteps,
}: {
  stepNameToInfoMap: CompanySetupStepNameToInfo;
  disregardCompleteSteps?: boolean;
}): CompanySetupStepConfig[] {
  const setupSteps: CompanySetupStepConfig[] = [];

  Object.entries(stepNameToInfoMap).forEach(([key, value]) => {
    if (value.isRelevantToJobs) {
      if (
        disregardCompleteSteps === undefined ||
        (disregardCompleteSteps &&
          [CompanySetupStepStateEnum.Pending, CompanySetupStepStateEnum.StartedPendingClientAction].includes(
            value.state
          ))
      ) {
        setupSteps.push({
          ...(COMPANY_SETUP_STEP_CONFIGS[key as CompanySetupStepStepNameEnum] as CompanySetupStepConstants),
          setupState: value.state,
        });
      }
    }
  });

  return setupSteps;
}

export function getEnrichedCompanySetupSteps({
  stepNameToInfoMap,
  disregardCompleteSteps,
}: {
  stepNameToInfoMap: CompanySetupStepNameToInfo;
  disregardCompleteSteps?: boolean;
}): EnrichedCompanySetupStep[] {
  const rawCompanySetupSteps: CompanySetupStepConfig[] = getCompanySetupSteps({
    stepNameToInfoMap: stepNameToInfoMap,
    disregardCompleteSteps,
  });

  return rawCompanySetupSteps.map(step => ({
    ...step,
    route: getStepRoute(step.path),
  }));
}
