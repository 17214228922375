/**
 * The comment below disables react-hooks eslint warnings for this entire file
 * Please feel free to remove that and instead fix the underlying eslint issue
 * For more information, visit https://app.shortcut.com/dover/epic/135236?cf_workflow=500017939&ct_workflow=all
 * TODO: Add link to a story for this page, part of the epic linked above
 */
/* eslint-disable react-hooks/exhaustive-deps */

import { Skeleton, Autocomplete, createFilterOptions, FilterOptionsState, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";

import AddProUserModal from "components/dover/inputs/pro-users/components/AddProUserModal";
import { StyledListItem } from "components/dover/inputs/pro-users/styles";
import { StyledTextField } from "components/dover/inputs/pro-users/styles";
import { BodySmall } from "components/library/typography";
import { useListProUsersForClientQuery } from "services/doverapi/endpoints/proUser";
import { getEntityById, listAllEntities } from "services/doverapi/entityAdapterUtils";
import { DoverUser } from "services/openapi";
import { colors } from "styles/theme";

const AutocompletePaperComponent: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return <Paper style={{ minWidth: "fit-content" }}>{children}</Paper>;
};

export interface ProUserSelectorProps {
  // if multiple, value is an array of proUserIds, otherwise it's a single proUserId
  value: number | number[] | null;
  onChange: (value: number | number[] | null) => void | Promise<void>;
  label?: string;
  required?: boolean;
  size?: "small" | "medium";
  multiple?: boolean;
  fullWidth?: boolean;
  placeholderText?: string;
  hideAddOption?: boolean;
}

const filter = createFilterOptions<number>();

const ProUserSelector = ({
  value,
  onChange,
  label,
  multiple,
  size,
  required = false,
  fullWidth = true,
  placeholderText,
  hideAddOption,
}: ProUserSelectorProps): React.ReactElement => {
  const [isAddProUserModalOpen, setIsAddProUserModalOpen] = useState<boolean>(false);
  if (multiple) {
    return (
      <ProUserSelectorGeneric
        onChange={(_event: React.SyntheticEvent | undefined, newVal: number[] | null): void => {
          if (newVal && newVal.includes(-1)) {
            setIsAddProUserModalOpen(true);
          } else {
            if (!newVal) {
              onChange([]);
            } else {
              onChange(newVal as number[]);
            }
          }
        }}
        handleCloseModal={(newProUser?: DoverUser): void => {
          if (newProUser?.pk) {
            onChange([...((value ?? []) as number[]), newProUser.pk]);
          }
          setIsAddProUserModalOpen(false);
        }}
        value={value as number[]}
        isAddProUserModalOpen={isAddProUserModalOpen}
        size={size}
        label={label}
        required={required}
        multiple={true}
        fullWidth={fullWidth}
        hideAddOption={hideAddOption}
        placeholderText={placeholderText}
      />
    );
  }

  return (
    <ProUserSelectorGeneric
      onChange={(_event: React.SyntheticEvent | undefined, newVal: number | null): void => {
        if (newVal === -1) {
          setIsAddProUserModalOpen(true);
        } else {
          onChange(newVal);
        }
      }}
      isAddProUserModalOpen={isAddProUserModalOpen}
      handleCloseModal={(newProUser?: DoverUser): void => {
        onChange((newProUser?.pk ?? null) as number | null);
        setIsAddProUserModalOpen(false);
      }}
      value={value as number | null}
      size={size}
      label={label}
      required={required}
      multiple={false}
      fullWidth={fullWidth}
      placeholderText={placeholderText}
      hideAddOption={hideAddOption}
    />
  );
};

interface ProUserSelectorGenericProps<T extends number | number[]> {
  onChange: (event: React.SyntheticEvent | undefined, value: T | null) => void;
  value: T | null;
  isAddProUserModalOpen: boolean;
  handleCloseModal: (newProUser?: DoverUser) => void;
  size?: "small" | "medium";
  label?: string;
  required?: boolean;
  multiple: boolean;
  fullWidth?: boolean;
  placeholderText?: string;
  hideAddOption?: boolean;
}

const ProUserSelectorGeneric = <T extends number | number[]>({
  onChange,
  value,
  isAddProUserModalOpen,
  handleCloseModal,
  size,
  label,
  required,
  multiple,
  fullWidth,
  placeholderText,
  hideAddOption,
}: ProUserSelectorGenericProps<T>): React.ReactElement => {
  const { proUsersEntityData, proUsers, isFetching } = useListProUsersForClientQuery(
    {},
    {
      selectFromResult: ({ data, isFetching }) => {
        const proUsers: DoverUser[] = listAllEntities(data);
        return {
          proUsersEntityData: data,
          proUsers,
          isFetching,
        };
      },
    }
  );
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const nullValue = Array.isArray(value) ? !value?.length : !value;
  const errorMessage = isTouched && required && nullValue ? "This field is required" : undefined;
  const hasError = !!errorMessage;

  useEffect(() => {
    // Unset the provided value if not found amongst those returned by our API
    if (proUsers && proUsers.length > 0 && !nullValue && !isFetching) {
      if (Array.isArray(value)) {
        const matchingProUsers: DoverUser[] | undefined = value
          .filter(proUserId => getProUserById(proUserId))
          .map(proUserId => getProUserById(proUserId)!);
        if (!matchingProUsers?.length) {
          onChange(undefined, null);
        }
      } else {
        const matchingProUser: DoverUser | undefined = getProUserById(value! as number);
        if (!matchingProUser) {
          onChange(undefined, null);
        }
      }

      return;
    }
  }, [proUsers, value, onChange]);

  const getProUserById = (proUserId: number): DoverUser | undefined => {
    return getEntityById(proUsersEntityData, proUserId);
  };
  return (
    <>
      <Autocomplete
        fullWidth={fullWidth}
        sx={{
          ".MuiInputBase-root": {
            border: "none",
          },
        }}
        multiple={multiple}
        value={value}
        // @ts-ignore
        onChange={(event, value): void => onChange(event, value)}
        PaperComponent={AutocompletePaperComponent}
        selectOnFocus
        handleHomeEndKeys
        filterOptions={(options: number[], params: FilterOptionsState<number>): any => {
          const filtered = filter(options, params);
          if (!hideAddOption) {
            // Add a new user option
            filtered.push(-1);
          }
          return filtered;
        }}
        options={proUsers ? proUsers.map(proUser => proUser.pk!) : []}
        getOptionLabel={(proUserIdOption): string => {
          proUserIdOption = proUserIdOption as number;
          if (proUserIdOption === -1) {
            return "Add a new user +";
          }
          const proUser = getProUserById(proUserIdOption);
          if (proUser === undefined) {
            return "";
          }
          if (proUser.fullName) {
            return proUser.fullName;
          }
          if (proUser.email) {
            return proUser.email;
          }

          return "";
        }}
        size={size}
        loading={isFetching}
        loadingText={<BodySmall>Loading...</BodySmall>}
        renderOption={(props, proUserIdOption: number): React.ReactElement => {
          if (proUserIdOption === -1) {
            return (
              // note: key prop must be declared last so that MUI's props.key (getOptionLabel) does
              // not override provided key
              // @ts-ignore
              <StyledListItem color={colors.informational.base} {...props} key={proUserIdOption}>
                <BodySmall>Add a new user +</BodySmall>
              </StyledListItem>
            );
          }

          const proUser: DoverUser = getProUserById(proUserIdOption)!;
          return (
            // @ts-ignore
            <StyledListItem {...props} key={proUserIdOption}>
              <BodySmall>{`${proUser.fullName || ""} ${proUser.email ? `(${proUser.email})` : " "}`}</BodySmall>
            </StyledListItem>
          );
        }}
        freeSolo
        renderInput={(params): React.ReactElement => {
          if (isFetching) {
            return <Skeleton width="100%" height="50px" />;
          }
          return (
            <StyledTextField
              {...params}
              variant="outlined"
              label={label}
              required={required}
              error={hasError}
              helperText={errorMessage}
              placeholder={placeholderText ?? "Select a team member"}
              onBlur={(): void => setIsTouched(true)}
            />
          );
        }}
      />
      <AddProUserModal open={isAddProUserModalOpen} handleClose={handleCloseModal} />
    </>
  );
};
export default ProUserSelector;
