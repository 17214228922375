/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateJobReferrer
 */
export interface UpdateJobReferrer {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateJobReferrer
     */
    disabled?: boolean | null;
}

export function UpdateJobReferrerFromJSON(json: any): UpdateJobReferrer {
    return UpdateJobReferrerFromJSONTyped(json, false);
}

export function UpdateJobReferrerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateJobReferrer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
    };
}

export function UpdateJobReferrerToJSON(value?: UpdateJobReferrer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disabled': value.disabled,
    };
}


