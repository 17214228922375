import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { CANDIDATE_SOURCE, CLIENT, LIST_TAG } from "services/doverapi/endpointTagsConstants";
import {
  BeginBillingPortalSessionRequest,
  BeginBillingPortalSessionResponse,
  BeginCheckoutRequest,
  BeginCheckoutResponse,
  CompleteCheckoutRequest,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";

const billingEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    beginCheckout: build.mutation<BeginCheckoutResponse, BeginCheckoutRequest>({
      queryFn: async ({ successUrl, cancelUrl }) => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        try {
          result = await client.beginCheckout({ data: { successUrl, cancelUrl } });
        } catch (error) {
          showErrorToast("Couldn't start checkout. Please try refreshing the page.");

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }

        return { data: result };
      },
    }),
    completeCheckout: build.mutation<{ success: boolean }, { clientId: string; data: CompleteCheckoutRequest }>({
      queryFn: async ({ data }) => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          await client.completeCheckout({ data });
        } catch (error) {
          showErrorToast("Couldn't complete checkout. Please try refreshing the page.");

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }

        return { data: { success: true } };
      },
      // Upon completing checkout, we should re-fetch candidate sources, since they may now be available to use.
      invalidatesTags: (result, error, arg) => [
        { type: CANDIDATE_SOURCE, id: LIST_TAG },
        { type: CLIENT, id: arg.clientId },
      ],
    }),
    beginBillingPortalSession: build.mutation<BeginBillingPortalSessionResponse, BeginBillingPortalSessionRequest>({
      queryFn: async (data: BeginBillingPortalSessionRequest) => {
        const { apiApi: client } = await getOpenApiClients({});

        let result;
        try {
          result = await client.beginBillingPortalSession({ data });
        } catch (error) {
          showErrorToast("Couldn't open billing portal. Please try refreshing the page.");

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }

        return { data: result };
      },
    }),
  }),
});

export const {
  useBeginCheckoutMutation,
  useCompleteCheckoutMutation,
  useBeginBillingPortalSessionMutation,
} = billingEndpoints;
