import { Stack } from "@mui/material";
import { DataGrid, GridEnrichedColDef, GridRenderCellParams, GridRowParams, GridSortModel } from "@mui/x-data-grid";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { format } from "date-fns";
import { useAtom, useAtomValue } from "jotai";
import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

import { CompanySetupSectionType } from "App/routing/types";
import {
  EmailTemplateDrawerAction,
  EmailTemplateDrawerConfigAtom,
  ToggleDeleteTemplateConfirmationModalAtom,
} from "components/dover/EmailTemplates/atoms";
import { EmailTemplateDrawerAtom } from "components/dover/EmailTemplates/EmailTemplateDrawer";
import { convertClientInterviewer } from "components/dover/top-level-modal-manager/utils/convertClientInterviewer";
import { Button, ButtonVariant } from "components/library/Button";
import { LearnMoreLink } from "components/library/LearnMoreLink";
import { Body, BodySmall, ButtonText, Overline, Subtitle2 } from "components/library/typography";
import CustomModal from "components/Modal";
import { useModal } from "GlobalOverlays/atoms";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import {
  useDeleteClientEmailTemplateMutation,
  useListClientEmailTemplatesQuery,
} from "services/doverapi/endpoints/client/endpoints";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { useListInterviewerEntities } from "services/doverapi/endpoints/interviewer";
import { ClientEmailTemplate } from "services/openapi";
import { colors } from "styles/theme";
import { H2 } from "styles/typography/headers";
import { toastOptions } from "utils/showToast";
import { emailTemplateNameFilterValAtom } from "views/CompanySetup/components/EmailTemplates/atoms";
import { EmailTemplatesSearchBar } from "views/CompanySetup/components/EmailTemplates/components/EmailTemplatesSearchBar";
import NewTemplateButton from "views/CompanySetup/components/EmailTemplates/components/NewTemplateButton";
import {
  AssignedJobsCell,
  CreatedByCell,
  NameCell,
  ActionsCell,
} from "views/CompanySetup/components/EmailTemplates/components/TemplateTableCells";
import { EMAIL_TEMPLATES_PAGE_LIMIT } from "views/CompanySetup/components/EmailTemplates/constants";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { companySectionConfiguration } from "views/CompanySetup/constants";

const GridWrapper = styled.div`
  .MuiDataGrid-columnsContainer {
    background: ${colors.grayscale.gray100};
  }
  .h-cell {
    background: ${colors.grayscale.gray100};
  }
  .MuiDataGrid-row {
    cursor: pointer;
  }
`;

const EmailTemplatesTableColumns: GridEnrichedColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    sortable: true,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <NameCell {...params} />,
  },
  {
    field: "jobsCount",
    headerName: "Assigned Jobs",
    minWidth: 120,
    sortable: true,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <AssignedJobsCell {...params} />,
  },
  {
    field: "modified",
    headerName: "Last Modified",
    minWidth: 140,
    width: 140,
    sortable: true,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params): React.ReactElement => {
      const modified = params.row.modified;
      if (!modified) return <BodySmall>-</BodySmall>;
      return <BodySmall>{format(new Date(modified), "MMM d, yyyy")}</BodySmall>;
    },
    valueGetter: (params): number | undefined => {
      return params.row.modified ? new Date(params.row.modified).getTime() : undefined;
    },
  },
  {
    field: "createdBy",
    headerName: "Created By",
    sortable: true,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <CreatedByCell {...params} />,
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    minWidth: 150,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <ActionsCell {...params} />,
  },
];

const FIELD_TO_ORDERING_MAP: Record<string, string> = {
  name: "name",
  jobsCount: "jobs_count",
  modified: "modified",
};

export const EmailTemplatesTable = (): React.ReactElement => {
  const hasImprovedSchedulingUX = useFeatureFlag(FeatureFlag.ImprovedSchedulingUX);

  // state & misc
  const [tablePage, setTablePage] = React.useState(0);
  const clientId = useGetClientId();
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

  // atoms
  const [emailTemplateDrawerConfig, setEmailTemplateDrawerConfig] = useAtom(EmailTemplateDrawerConfigAtom);
  const { open: openEmailTemplateDrawer } = useModal(EmailTemplateDrawerAtom);
  const [deleteConfirmationModalOpen, toggleDeleteConfirmationModal] = useAtom(
    ToggleDeleteTemplateConfirmationModalAtom
  );

  const emailTemplateNameFilterVal = useAtomValue(emailTemplateNameFilterValAtom);

  const getOrdering = (model: GridSortModel): string | undefined => {
    if (!model.length) return undefined;

    // Only use first sort field since we only support single-field ordering
    const { field, sort } = model[0];
    const prefix = sort === "desc" ? "-" : "";

    return `${prefix}${FIELD_TO_ORDERING_MAP[field]}`;
  };

  // RTK
  const interviewers = useListInterviewerEntities();
  const {
    currentData: { results: emailTemplates, count: templateCount } = {},
    isFetching: isFetchingTemplates,
  } = useListClientEmailTemplatesQuery(
    clientId
      ? {
          offset: tablePage * EMAIL_TEMPLATES_PAGE_LIMIT,
          limit: EMAIL_TEMPLATES_PAGE_LIMIT,
          name: emailTemplateNameFilterVal,
          ordering: getOrdering(sortModel),
        }
      : skipToken
  );
  const [deleteClientEmailTemplate, { isLoading: isDeletingTemplate }] = useDeleteClientEmailTemplateMutation();

  // callbacks
  const onEdit = (emailTemplate: ClientEmailTemplate): void => {
    setEmailTemplateDrawerConfig({
      template: emailTemplate,
      action: EmailTemplateDrawerAction.Update,
      interviewerSelectorProps: {
        enabled: hasImprovedSchedulingUX,
        defaultInterviewer: emailTemplate.defaultInterviewer
          ? convertClientInterviewer(
              interviewers.find(interviewer => interviewer.id === emailTemplate.defaultInterviewer)
            )
          : undefined,
      },
    });
    openEmailTemplateDrawer({});
  };

  const onDelete = async (): Promise<void> => {
    if (!emailTemplateDrawerConfig?.template?.id) {
      return;
    }

    const deleteTemplateArgs = {
      id: emailTemplateDrawerConfig.template.id,
    };

    const deleteTemplatePromise = deleteClientEmailTemplate(deleteTemplateArgs).unwrap();
    try {
      await toast.promise(
        deleteTemplatePromise,
        {
          pending: "Deleting template..",
          error: "Error deleting template",
        },
        { ...toastOptions }
      );
    } catch (e) {
      console.error(e);
    } finally {
      setEmailTemplateDrawerConfig({
        template: undefined,
        action: undefined,
      });
      toggleDeleteConfirmationModal(false);
    }
    return;
  };

  return (
    <SectionWrapper config={companySectionConfiguration[CompanySetupSectionType.EMAIL_TEMPLATES]}>
      {/* Confirmation modal for deleting templates */}
      <CustomModal
        open={deleteConfirmationModalOpen}
        title={<Subtitle2>Delete template?</Subtitle2>}
        onClose={(): void => toggleDeleteConfirmationModal(false)}
        maxWidth="sm"
        dialogActions={
          <Stack direction="row" spacing={1}>
            <Button onClick={(): void => toggleDeleteConfirmationModal(false)} variant={ButtonVariant.Secondary}>
              <ButtonText>Cancel</ButtonText>
            </Button>
            <Button onClick={onDelete} variant={ButtonVariant.Critical} disabled={isDeletingTemplate}>
              <ButtonText color={colors.white}>Remove</ButtonText>
            </Button>
          </Stack>
        }
      >
        <Body>This action can not be undone.</Body>
      </CustomModal>
      {/* Templates table */}
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ alignItems: "start" }}>
          <Stack spacing={1}>
            <H2>Email Templates</H2>
            <LearnMoreLink
              description="Create reusable email templates to save time when sending common interview communications like scheduling, updates, and rejections."
              linkUrl="https://help.dover.com/en/articles/9261450-email-templates"
            />
          </Stack>
          <Stack direction="row" spacing={1} minWidth="260px" sx={{ alignItems: "center" }}>
            <EmailTemplatesSearchBar />
            <NewTemplateButton style={{ height: "40px" }} />
          </Stack>
        </Stack>
        <GridWrapper>
          <DataGrid
            loading={isFetchingTemplates}
            autoHeight
            headerHeight={40}
            rowHeight={64}
            rows={emailTemplates ?? []}
            rowCount={templateCount}
            columns={EmailTemplatesTableColumns}
            pagination
            paginationMode="server"
            sortingMode="server"
            sortModel={sortModel}
            page={tablePage}
            pageSize={EMAIL_TEMPLATES_PAGE_LIMIT}
            onPageChange={setTablePage}
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            localeText={{ noRowsLabel: "Click '+New' to create a new email template" }}
            onRowClick={(params: GridRowParams): void => {
              onEdit(params.row as ClientEmailTemplate);
            }}
            onSortModelChange={setSortModel}
          />
        </GridWrapper>
      </Stack>
    </SectionWrapper>
  );
};
