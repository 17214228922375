import { Box, List, ListItemButton, Pagination, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";

import { ApplicationReview } from "services/openapi";

interface SelectableListProps {
  items: ApplicationReview[];
  onSelectItem: (item: ApplicationReview) => void;
  totalPages: number;
  onPageSelect: (page: number) => void;
}

const PaginatedSelectableList = ({
  items,
  onSelectItem,
  totalPages,
  onPageSelect,
}: SelectableListProps): React.ReactElement => {
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        maxHeight: "100%",
      }}
    >
      <List
        sx={{
          overflowY: "scroll",
          paddingBottom: theme.spacing(6),
          width: "100%",
        }}
      >
        {items.map((item, i) => (
          <ListItemButton
            sx={{ width: "100%", padding: theme.spacing(2) }}
            key={`selectable-item-${i}`}
            selected={selectedItem == i}
            onClick={(): void => {
              setSelectedItem(i);
              onSelectItem(item);
            }}
          >
            <Typography variant="subtitle1">{item.application}</Typography>
          </ListItemButton>
        ))}
      </List>
      <Box
        sx={{
          display: "flex",
          position: "absolute",
          bottom: 0,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.palette.background.default,
          height: theme.spacing(7),
        }}
      >
        <Pagination
          size="small"
          count={totalPages}
          page={currentPage}
          onChange={(evt, page): void => {
            setCurrentPage(page);
            onPageSelect(page);
          }}
        />
      </Box>
    </Box>
  );
};

export default PaginatedSelectableList;
