/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CandidateBioSource
 */
export interface CandidateBioSource {
    /**
     * 
     * @type {string}
     * @memberof CandidateBioSource
     */
    direction: CandidateBioSourceDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof CandidateBioSource
     */
    internalName: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateBioSource
     */
    label: string;
    /**
     * 
     * @type {Date}
     * @memberof CandidateBioSource
     */
    pipelineStartedDate: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CandidateBioSource
     */
    displayCategory: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateBioSource
     */
    displayAction: string;
    /**
     * 
     * @type {string}
     * @memberof CandidateBioSource
     */
    applicationUrl: string;
}

/**
* @export
* @enum {string}
*/
export enum CandidateBioSourceDirectionEnum {
    Inbound = 'INBOUND',
    Outbound = 'OUTBOUND'
}

export function CandidateBioSourceFromJSON(json: any): CandidateBioSource {
    return CandidateBioSourceFromJSONTyped(json, false);
}

export function CandidateBioSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateBioSource {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'direction': json['direction'],
        'internalName': json['internal_name'],
        'label': json['label'],
        'pipelineStartedDate': (json['pipeline_started_date'] === null ? null : new Date(json['pipeline_started_date'])),
        'displayCategory': json['display_category'],
        'displayAction': json['display_action'],
        'applicationUrl': json['application_url'],
    };
}

export function CandidateBioSourceToJSON(value?: CandidateBioSource | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'direction': value.direction,
        'internal_name': value.internalName,
        'label': value.label,
        'pipeline_started_date': (value.pipelineStartedDate === null ? null : value.pipelineStartedDate.toISOString().substr(0,10)),
        'display_category': value.displayCategory,
        'display_action': value.displayAction,
        'application_url': value.applicationUrl,
    };
}


