import { Editor } from "@tiptap/react";
import { useAtom } from "jotai";

import { EmailTemplateDrawerAction, EmailTemplateDrawerConfigAtom } from "components/dover/EmailTemplates/atoms";
import { EmailTemplateDrawerAtom } from "components/dover/EmailTemplates/EmailTemplateDrawer";
import { Interviewer } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerAutoComplete";
import { useModal } from "GlobalOverlays/atoms";
import { useParseClientEmailTemplateMutation } from "services/doverapi/endpoints/client/endpoints";
import { ClientEmailTemplate } from "services/openapi";

export const useSaveAsNewTemplate = (
  editor: Editor | null,
  disabled?: boolean,
  subject: string = "Placeholder Subject",
  name: string = "New Template"
): ((interviewerSelectorProps?: { enabled?: boolean; defaultInterviewer?: Interviewer }) => Promise<void>) => {
  const [parseClientEmailTemplateMutation] = useParseClientEmailTemplateMutation();
  const { open: openEmailTemplateDrawer } = useModal(EmailTemplateDrawerAtom);
  const [, setEmailTemplateDrawerConfig] = useAtom(EmailTemplateDrawerConfigAtom);

  const handleSaveAsNewTemplate = async (
    interviewerSelectorProps:
      | {
          enabled?: boolean;
          defaultInterviewer?: Interviewer;
        }
      | undefined = undefined
  ): Promise<void> => {
    if (!editor || disabled) {
      return;
    }

    const emailContent = editor.getHTML();
    const res = await parseClientEmailTemplateMutation({ data: { body: emailContent } }).unwrap();

    setEmailTemplateDrawerConfig({
      template: {
        name: name,
        bodyTemplate: res.body,
        subjectTemplate: subject,
      } as ClientEmailTemplate,
      interviewerSelectorProps,
      action: EmailTemplateDrawerAction.Create,
    });
    openEmailTemplateDrawer({});
  };

  return handleSaveAsNewTemplate;
};
