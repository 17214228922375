import { Box, Drawer, IconButton, Stack, TextField as MUITextField, StackProps } from "@mui/material";
import { Select } from "mui-rff";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

import { Caption } from "components/library/typography";
import { colors } from "styles/theme";
import { B2_doNotUse } from "styles/typography";

export const ActionsContainer = styled(Stack)`
  justify-content: flex-end;
  align-items: center;
  button {
    padding: 5px 10px;
  }
`;

export const StageDrawer = styled(Drawer)`
  &.MuiDrawer-root {
    z-index: 1202;
  }

  .MuiDrawer-paperAnchorRight {
    width: 85vw;
    max-width: 1000px;
    height: 100vh;
  }
  .MuiSelect-select {
    padding: 10px 8px !important;
    font-size: 14px !important;
  }
  .MuiTextField-root,
  .MuiInputBase-root {
    width: 100% !important;
  }
  .MuiSelect-root {
    max-height: 40px;
  }
  .MuiAutocomplete-inputRoot {
    padding: 2px 8px;
  }
`;

export const StageNameField = styled(MUITextField)`
  .MuiOutlinedInput-notchedOutline legend {
    display: none;
  }
  .MuiInputBase-input {
    padding: 6px 8px !important;
  }
  &.MuiTextField-root {
    * {
      font-family: Inter;
      font-size: 14px !important;
    }
  }

  .MuiOutlinedInput-root {
    height: 40px;
    border: none;
  }
`;

export const StageDrawerContent = styled(Box)`
  padding: 16px 0 50px;
  overflow-y: auto;
  min-height: 0;
`;

export const StageDrawerActions = styled(Stack)`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: white;
  height: 85px;
  z-index: 1400;
  padding: 15px 65px 15px 15px;
  border-top: solid 1px ${colors.grayscale.gray300};
`;

export const StageDrawerCloseIcon = styled(IconButton)`
  position: relative;
  bottom: 3px;

  &.MuiButtonBase-root {
    transform: rotate(45deg);
    width: 35px;
    height: 35px;
    float: right;
    color: ${colors.black};
    font-size: 28px;
  }
`;

export const StageDrawerFormLabel = styled(B2_doNotUse)`
  margin-bottom: 5px;
`;

export const StageDrawerFormError = styled(Caption)`
  color: ${colors.critical.base};
  margin-top: 5px;
`;

export const FormLabelInfoIcon = styled(ReactSVG)`
  position: relative;
  bottom: 5px;
`;

export const DurationField = styled(Select)`
  max-width: 130px;
  background-color: ${colors.white};
  &.MuiInputBase-root {
    height: 40px;
  }
`;

interface InterviewDurationContainerProps extends StackProps {
  $isInline: boolean;
}
export const InterviewDurationContainer = styled(Box)<InterviewDurationContainerProps>`
  width: ${(props): string => (props.$isInline ? "20%" : "100%")};
  display: ${(props): string => (props.$isInline ? "inline-block" : "block")};
  margin-bottom: 24px;
  .MuiInputBase-root {
    max-width: ${(props): string => (props.$isInline ? "calc(100% - 16px)" : "130px")};
  }
`;

export const RequiredAsterisk = styled.span`
  color: ${colors.critical.base};
`;
