import { combineReducers, configureStore, getDefaultMiddleware, isPlain } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query/react";
import { applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import { doverApi } from "services/doverapi/apiSlice";

const isSerializable = (value: any): boolean => {
  /* Determines whether a provided value should be considered serializable prior to persisting it to redux */

  const checks: Array<(v: any) => boolean> = [
    // Default serializability check that RTK performs
    (checkValue): boolean => isPlain(checkValue),
    // Don't log warnings when trying to persist Date objects to the redux store.
    (checkValue): boolean => checkValue instanceof Date,
    (checkValue): boolean => checkValue instanceof Uint8Array,
  ];

  for (const check of checks) {
    if (check(value)) {
      return true;
    }
  }

  // Otherwise, it's not, and we should let RTK console out a warning
  return false;
};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    [doverApi.reducerPath]: doverApi.reducer,
    ...injectedReducers,
  });

  return rootReducer;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function configureStoreWithAddons(initialState: object = {}) {
  let composeEnhancers = compose;
  const reduxSagaMonitorOptions = {};

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.NODE_ENV !== "production" && typeof window === "object") {
    /* eslint-disable no-underscore-dangle */
    // @ts-ignore
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
    /* eslint-enable */
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware];

  const store = configureStore({
    reducer: createReducer({}),
    preloadedState: initialState,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: {
          isSerializable,
        },
      }).concat([doverApi.middleware]),
      ...middlewares,
    ],
  });
  const enhancers = [applyMiddleware(...middlewares)];
  composeEnhancers(...enhancers);

  // Extensions
  // @ts-ignore
  store.runSaga = sagaMiddleware.run;
  // @ts-ignore
  store.injectedReducers = {}; // Reducer registry
  // @ts-ignore
  store.injectedSagas = {}; // Saga registry

  setupListeners(store.dispatch);

  return store;
}
