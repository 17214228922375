import { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";
import React from "react";

import { StyledDrawer } from "components/library/Drawer/styles";

interface DrawerProps extends MuiDrawerProps {
  children: React.ReactNode;
}

const Drawer = ({ children, ...rest }: DrawerProps): React.ReactElement => {
  return <StyledDrawer {...rest}>{children}</StyledDrawer>;
};

export default Drawer;
