/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CETProUser,
    CETProUserFromJSON,
    CETProUserFromJSONTyped,
    CETProUserToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClientEmailTemplate
 */
export interface ClientEmailTemplate {
    /**
     * 
     * @type {string}
     * @memberof ClientEmailTemplate
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientEmailTemplate
     */
    name: string;
    /**
     * 
     * @type {object}
     * @memberof ClientEmailTemplate
     */
    readonly jobs?: object;
    /**
     * 
     * @type {string}
     * @memberof ClientEmailTemplate
     */
    subjectTemplate: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientEmailTemplate
     */
    bodyTemplate: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientEmailTemplate
     */
    ccEmails: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientEmailTemplate
     */
    bccEmails: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ClientEmailTemplate
     */
    client: string;
    /**
     * 
     * @type {string}
     * @memberof ClientEmailTemplate
     */
    defaultInterviewer?: string | null;
    /**
     * 
     * @type {CETProUser}
     * @memberof ClientEmailTemplate
     */
    createdBy?: CETProUser | null;
    /**
     * 
     * @type {Date}
     * @memberof ClientEmailTemplate
     */
    modified?: Date;
    /**
     * 
     * @type {number}
     * @memberof ClientEmailTemplate
     */
    jobsCount?: number | null;
}

export function ClientEmailTemplateFromJSON(json: any): ClientEmailTemplate {
    return ClientEmailTemplateFromJSONTyped(json, false);
}

export function ClientEmailTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientEmailTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'jobs': !exists(json, 'jobs') ? undefined : json['jobs'],
        'subjectTemplate': json['subject_template'],
        'bodyTemplate': json['body_template'],
        'ccEmails': json['cc_emails'],
        'bccEmails': json['bcc_emails'],
        'client': json['client'],
        'defaultInterviewer': !exists(json, 'default_interviewer') ? undefined : json['default_interviewer'],
        'createdBy': !exists(json, 'created_by') ? undefined : CETProUserFromJSON(json['created_by']),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'jobsCount': !exists(json, 'jobs_count') ? undefined : json['jobs_count'],
    };
}

export function ClientEmailTemplateToJSON(value?: ClientEmailTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'subject_template': value.subjectTemplate,
        'body_template': value.bodyTemplate,
        'cc_emails': value.ccEmails,
        'bcc_emails': value.bccEmails,
        'client': value.client,
        'default_interviewer': value.defaultInterviewer,
        'created_by': CETProUserToJSON(value.createdBy),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'jobs_count': value.jobsCount,
    };
}


