import { escapeRegExp } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import { BasicEmailOption } from "components/library/TipTap/types";
import { useListClientDomainsQuery } from "services/doverapi/endpoints/company-setup/endpoints";
import { useSendNewUserInvitationMutation } from "services/doverapi/endpoints/emailSending";
import { toastOptions } from "utils/showToast";

export const useInviteNewUsersForm = (): {
  emailAddresses: BasicEmailOption[];
  setEmailAddresses: React.Dispatch<React.SetStateAction<BasicEmailOption[]>>;
  customEmailMessage: string;
  setCustomEmailMessage: React.Dispatch<React.SetStateAction<string>>;
  emailAddressesAreValid: boolean;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  emailAddressIsValid: (emailAddress: string) => boolean;
  sendInvitations: () => Promise<{ success: boolean }>;
  isSendingInvitations: boolean;
  submitDisabled: boolean;
} => {
  const [sendNewUserInvitations, { isLoading: isSendingInvitations }] = useSendNewUserInvitationMutation();
  const { data: clientDomains } = useListClientDomainsQuery({});

  const [emailAddresses, setEmailAddresses] = useState<BasicEmailOption[]>([]);
  const [customEmailMessage, setCustomEmailMessage] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");

  // email must start with a letter, number, underscore, period, or dash followed by the @ symbol, ending with a valid domain
  const emailRegex = useMemo(
    () => new RegExp(`^[\\w-\\.\\_]+@.*(${clientDomains?.map(domain => escapeRegExp(domain.domain)).join("|")})$`),
    [clientDomains]
  );

  const emailAddressIsValid = useCallback(
    (emailAddress: string): boolean => {
      if (!emailAddress) {
        return false;
      }
      return emailRegex.test(emailAddress);
    },
    [emailRegex]
  );

  const emailAddressesAreValid = useMemo(() => {
    return emailAddresses.every(emailAddress => emailAddressIsValid(emailAddress.email));
  }, [emailAddresses, emailAddressIsValid]);

  const sendInvitations = useCallback(async () => {
    if (!emailAddressesAreValid) {
      return { success: false };
    }
    const emailAddressesToSend = [...emailAddresses];
    if (emailAddressIsValid(inputValue)) {
      emailAddressesToSend.push({ email: inputValue, id: uuidv4() });
    }
    const promises = emailAddressesToSend.map(emailAddress => {
      return sendNewUserInvitations({
        toEmails: [emailAddress.email],
        invitationMessage: customEmailMessage === "<p></p>" ? "" : customEmailMessage,
      });
    });
    const result = await toast.promise(
      Promise.all(promises),
      {
        pending: "Emailing invite(s)...",
        success: "Successfully emailed invite(s)",
        error: "Failed to send invitations, please try again",
      },
      {
        ...toastOptions,
      }
    );
    if ("error" in result) {
      return { success: false };
    }
    setEmailAddresses([]);
    setCustomEmailMessage("");
    return { success: true };
  }, [
    emailAddresses,
    customEmailMessage,
    emailAddressesAreValid,
    sendNewUserInvitations,
    inputValue,
    emailAddressIsValid,
  ]);

  const submitDisabled = useMemo(() => {
    if (isSendingInvitations) {
      return true;
    }
    if (emailAddressIsValid(inputValue) && emailAddresses.length === 0) {
      return false;
    }
    return !emailAddressesAreValid || emailAddresses.length === 0;
  }, [emailAddressIsValid, emailAddresses, emailAddressesAreValid, inputValue, isSendingInvitations]);

  return {
    emailAddresses,
    setEmailAddresses,
    customEmailMessage,
    setCustomEmailMessage,
    emailAddressesAreValid,
    emailAddressIsValid,
    inputValue,
    setInputValue,
    sendInvitations,
    isSendingInvitations,
    submitDisabled,
  };
};
