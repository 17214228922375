import { Box } from "@mui/material";
import { useSetAtom } from "jotai";
import React, { FC, useEffect } from "react";

import { ReactComponent as SearchIcon } from "assets/icons/magnifying-glass.svg";
import { TextField } from "components/library/TextField";
import { useDebounceState } from "hooks/useDebounceState";
import { colors } from "styles/theme";
import { emailTemplateNameFilterValAtom } from "views/CompanySetup/components/EmailTemplates/atoms";

export const EmailTemplatesSearchBar: FC<React.PropsWithChildren<unknown>> = () => {
  const setNameFilterVal = useSetAtom(emailTemplateNameFilterValAtom);

  const [debouncedNameSearch, setNameSearch, nameSearch] = useDebounceState<string>("", 500);

  // Debounce the name updates before it hits jotai so that we don't recall the api too often
  useEffect(() => {
    setNameFilterVal(debouncedNameSearch);
  }, [debouncedNameSearch, setNameFilterVal]);

  return (
    <Box p={0.5}>
      <TextField
        fullWidth
        text={nameSearch}
        onTextUpdated={setNameSearch}
        placeholderText="Search"
        startAdornment={<SearchIcon width="16px" height="16px" color={colors.grayscale.gray500} className="svg-fill" />}
      />
    </Box>
  );
};
