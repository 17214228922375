import { zodResolver } from "@hookform/resolvers/zod";
import { Stack, FormControl, FormHelperText, Input, Box, useTheme, Checkbox, FormControlLabel } from "@mui/material";
import React, { ReactNode, useCallback, useEffect, useMemo } from "react";
import { FormProvider, useController, useForm, useWatch } from "react-hook-form";

import { ReactComponent as ArrowRight } from "assets/icons/arrow-right.svg";
import MultipleTitlesAutocomplete from "components/library/Autocomplete/MultipleTitlesAutocomplete";
import { Button, ButtonVariant } from "components/library/Button";
import { Subtitle1, Subtitle2 } from "components/library/typography";
import { ApplicationReview } from "services/openapi";
import { colors } from "styles/theme";
import { usePatchApplicationReviewMutation } from "views/ApplicationsReviewer/endpoints";
import { ApplicationReviewSchema } from "views/ApplicationsReviewer/types";

interface ApplicationReviewFormProps {
  data: ApplicationReview;
  afterSubmitCb?: (id?: string) => void;
}

const ApplicationReviewForm = ({ data, afterSubmitCb }: ApplicationReviewFormProps): React.ReactElement => {
  const FORM_CONTAINER_ID = "applications-reviewer-form";

  const [patchApp, { isLoading: isSubmitting }] = usePatchApplicationReviewMutation();

  const formMethods = useForm<ApplicationReview>({
    resolver: zodResolver(ApplicationReviewSchema),
    defaultValues: useMemo(() => data, [data]),
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = formMethods;

  useEffect(() => reset(data), [data, reset]);

  const onSubmit = async (data: ApplicationReview): Promise<void> => {
    if (data.id) {
      await patchApp({
        id: data.id,
        data: {
          ...(data.annotatedTitleRaw1 ? { annotatedTitleRaw1: data.annotatedTitleRaw1 } : {}),
          ...(data.annotatedTitleRaw2 ? { annotatedTitleRaw2: data.annotatedTitleRaw2 } : {}),
          ...(data.annotatedTitleT21 ? { annotatedTitleT21: data.annotatedTitleT21 } : {}),
          ...(data.annotatedTitleT22 ? { annotatedTitleT22: data.annotatedTitleT22 } : {}),
          ...(data.annotatedTotalYoe ? { annotatedTotalYoe: data.annotatedTotalYoe } : {}),
          ...(data.annotatedLocation ? { annotatedLocation: data.annotatedLocation } : {}),
          ...(data.annotatedJobCategory ? { annotatedJobCategory: data.annotatedJobCategory } : {}),
          annotatedHasSeniority: data.annotatedHasSeniority,
          annotatedHasTargetLocations: data.annotatedHasTargetLocations,
          reviewed: true,
        },
      });
      if (afterSubmitCb) afterSubmitCb(data.id);
    }
  };

  const titlesT1 = [
    useWatch({ control, name: "annotatedTitleRaw1" }),
    useWatch({ control, name: "annotatedTitleRaw2" }),
  ];

  const titlesT2 = [useWatch({ control, name: "annotatedTitleT21" }), useWatch({ control, name: "annotatedTitleT22" })];

  const onTitleT1Change = useCallback(
    (titles: string[]) => {
      if (titles.length) {
        setValue("annotatedTitleRaw1", titles[0]);
        if (titles.length > 1) {
          setValue("annotatedTitleRaw2", titles[1]);
        } else {
          setValue("annotatedTitleRaw2", "");
        }
      } else {
        setValue("annotatedTitleRaw1", "");
      }
    },
    [setValue]
  );

  const onTitleT2Change = useCallback(
    (titles: string[]) => {
      if (titles.length) {
        setValue("annotatedTitleT21", titles[0]);
      }

      if (titles.length > 1) {
        setValue("annotatedTitleT22", titles[1]);
      }
    },
    [setValue]
  );

  const onLocationChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      if ((evt.target as HTMLInputElement).value) {
        setValue("annotatedLocation", evt.target.value);
      }
    },
    [setValue]
  );

  const location = useWatch({ control, name: "annotatedLocation" });

  const onJobCategoryChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setValue("annotatedJobCategory", evt.target.value);
    },
    [setValue]
  );

  const jobCategory = useWatch({ control, name: "annotatedJobCategory" });

  const onHasSeniorityChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setValue("annotatedHasSeniority", evt.target.checked);
    },
    [setValue]
  );

  const hasSeniority = useWatch({ control, name: "annotatedHasSeniority" });

  const onHasTargetLocationsChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setValue("annotatedHasTargetLocations", evt.target.checked);
    },
    [setValue]
  );

  const hasTargetLocations = useWatch({ control, name: "annotatedHasTargetLocations" });

  const onYoEChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      if (evt.target.value) {
        setValue("annotatedTotalYoe", parseInt(evt.target.value));
      }
    },
    [setValue]
  );

  const yoe = useWatch({ control, name: "annotatedTotalYoe" });

  const { field: locationController } = useController({ control, name: "annotatedLocation" });
  const { field: yoeController } = useController({ control, name: "annotatedTotalYoe" });

  const theme = useTheme();

  return (
    <Stack id={FORM_CONTAINER_ID} spacing={2} sx={{ position: "relative", height: "100%" }}>
      <Box p={1} sx={{ zIndex: 1000 }}>
        <Subtitle1>{data.application}</Subtitle1>
      </Box>

      <Box sx={{ overflowY: "scroll", paddingBottom: theme.spacing(6) }}>
        <FormProvider {...formMethods}>
          <Stack p={1} spacing={1}>
            <FormControl>
              <Subtitle2>Total YoE</Subtitle2>
              <Input type="number" {...yoeController} onChange={onYoEChange} value={yoe ?? 0} />
              {errors.annotatedTotalYoe && <FormHelperText error>{errors.annotatedTotalYoe.message}</FormHelperText>}
            </FormControl>

            <FormControl>
              <Subtitle2>T1 Titles</Subtitle2>
              <MultipleTitlesAutocomplete
                max={2}
                error={errors.annotatedTitleRaw1?.message || errors.annotatedTitleRaw2?.message}
                onChange={onTitleT1Change}
                label="T1 Titles"
                freeSolo
                values={titlesT1.filter(v => v) as string[]}
              />
            </FormControl>

            <FormControl>
              <Subtitle2>T2 Titles</Subtitle2>
              <MultipleTitlesAutocomplete
                error={errors.annotatedTitleT21?.message || errors.annotatedTitleT22?.message}
                max={2}
                onChange={onTitleT2Change}
                label="T2 Titles"
                freeSolo
                values={titlesT2.filter(v => v) as string[]}
              />
            </FormControl>

            <FormControl>
              <Subtitle2>Location</Subtitle2>
              <Input {...locationController} onChange={onLocationChange} value={location ?? ""} />
              {errors.annotatedLocation && <FormHelperText error>{errors.annotatedLocation.message}</FormHelperText>}
            </FormControl>

            <FormControl>
              <Subtitle2>Job Category</Subtitle2>
              <Input onChange={onJobCategoryChange} value={jobCategory ?? ""} />
              {errors.annotatedJobCategory && (
                <FormHelperText error>{errors.annotatedJobCategory.message}</FormHelperText>
              )}
            </FormControl>

            <Stack direction="row">
              <FormControlLabel
                control={<Checkbox onChange={onHasSeniorityChange} checked={hasSeniority ?? undefined} />}
                label={(<Subtitle2>Has Seniority</Subtitle2>) as ReactNode}
              />
              <FormControlLabel
                control={<Checkbox onChange={onHasTargetLocationsChange} checked={hasTargetLocations ?? undefined} />}
                label={<Subtitle2>Has Target Locations</Subtitle2>}
              />
            </Stack>
          </Stack>
        </FormProvider>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: theme.spacing(6),
          backgroundColor: theme.palette.background.default,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        p={1}
      >
        <Button variant={ButtonVariant.Primary} onClick={handleSubmit(onSubmit)} loading={isSubmitting} width="100%">
          Next <ArrowRight display="inline" color={colors.white} className="svg-color" />
        </Button>
      </Box>
    </Stack>
  );
};

export default ApplicationReviewForm;
