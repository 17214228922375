import { Divider, Stack } from "@mui/material";
import React, { FC } from "react";

import { CandidateBio } from "services/openapi";
import { ApplicantName } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/ApplicantName";
import { AtsInfo } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/AtsInfo";
import { ContactInfo } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/ContactInfo";
import { DisplayCategory } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/DisplayCategory";
import { ResumeDownload } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/ResumeDownload";
import { SocialLinksV2 } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/SocialLinks";
import { SourceInfo } from "views/candidates/ApplicationReview/components/MainPanel/ApplicantInfo/SourceInfo";

interface ApplicantInfoProps {
  candidateBio: CandidateBio;
  resumePath?: string;
}

export const ApplicantInfo: FC<ApplicantInfoProps> = ({ candidateBio, resumePath }) => {
  const fullName = candidateBio?.contact?.fullName;

  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" justifyContent="space-between">
      <Stack direction="row" spacing={1}>
        <ApplicantName fullName={fullName} isSample={candidateBio.isDemoInboundCand} />
        {candidateBio.id && (
          <SocialLinksV2 candidateId={candidateBio.id} socialLinks={candidateBio.contact.socialLinks || []} />
        )}
        {resumePath && <ResumeDownload resumePath={resumePath} />}
      </Stack>
      <Stack
        direction="row"
        spacing={0.5}
        alignItems="center"
        divider={<Divider orientation="vertical" sx={{ height: "10px", mr: "4px" }} />}
      >
        {candidateBio.searchSource && <DisplayCategory source={candidateBio.searchSource} />}
        {candidateBio.ats && <AtsInfo ats={candidateBio.ats} />}
        <ContactInfo candidateBio={candidateBio} />
        {candidateBio.searchSource && <SourceInfo source={candidateBio.searchSource} />}
      </Stack>
    </Stack>
  );
};
