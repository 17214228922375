import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import { LIST_APPLICATION_REVIEWS } from "services/doverapi/endpointTagsConstants";
import {
  ApplicationReview,
  InlineResponse20044,
  ApiApiListApplicationReviewsRequest,
  ApiApiPartialUpdateApplicationReviewRequest,
} from "services/openapi";
import { showErrorToast } from "utils/showToast";

const applicationsReviewerFormEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    listApplicationReviews: build.query<InlineResponse20044, ApiApiListApplicationReviewsRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.listApplicationReviews(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to list application reviews");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: () => {
        return [{ type: LIST_APPLICATION_REVIEWS }];
      },
    }),
    patchApplicationReview: build.mutation<Partial<ApplicationReview>, ApiApiPartialUpdateApplicationReviewRequest>({
      queryFn: async request => {
        const { apiApi } = await getOpenApiClients({});
        try {
          const response = await apiApi.partialUpdateApplicationReview(request);
          return { data: response };
        } catch (error) {
          showErrorToast("Failed to update application review");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      invalidatesTags: () => {
        return [{ type: LIST_APPLICATION_REVIEWS }];
      },
    }),
  }),
});

export const { useListApplicationReviewsQuery, usePatchApplicationReviewMutation } = applicationsReviewerFormEndpoints;
