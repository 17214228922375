import { Autocomplete, Chip, TextField } from "@mui/material";
import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from "react";

import { useListTitlesQuery } from "services/doverapi/endpoints/search-v3/endpoints";

interface MultipleTitlesAutocompleteProps {
  onChange: (item: string[]) => void;
  freeSolo?: boolean;
  label?: string;
  placeholder?: string;
  limitTagsOnUnfocus?: number;
  max?: number;
  error?: string;
  values: string[];
}

export default function MultipleTitlesAutocomplete({
  onChange,
  max,
  freeSolo,
  label,
  placeholder,
  limitTagsOnUnfocus,
  error,
  values,
}: MultipleTitlesAutocompleteProps): ReactElement {
  const { data: allTitles, isLoading: loading } = useListTitlesQuery();
  const [inputValue, setInputValue] = useState("");

  const onSelect = useCallback(
    (values: string[]): void => {
      let currentValues = [...values];

      if (max && currentValues.length > max) {
        const lastValue = currentValues.pop();
        currentValues.pop();
        if (lastValue) currentValues = [...currentValues, lastValue];
      }

      onChange(currentValues);
    },
    [max, onChange]
  );

  useEffect((): (() => void) => {
    const timer = setTimeout(() => {
      if (inputValue) {
        onSelect([...values, inputValue]);

        setInputValue("");
      }
    }, 300);

    return (): void => {
      clearTimeout(timer);
    };
  }, [inputValue, values, onSelect]);

  useEffect((): void => {
    onChange(values);
  }, [values, onChange]);

  return (
    <Autocomplete
      multiple
      value={values}
      onChange={(evt: React.SyntheticEvent<Element, Event>, values: string[]): void => {
        onSelect(values);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue, reason): void => {
        if (reason == "reset") {
          setInputValue("");
          return;
        }

        setInputValue(newInputValue);
      }}
      limitTags={limitTagsOnUnfocus ?? 2}
      id="multiple-titles-autocomplete"
      options={allTitles ? allTitles.map(option => option.name ?? "") : []}
      freeSolo={freeSolo}
      loading={loading}
      renderTags={(value: readonly string[], getTagProps): ReactNode => {
        return value.map((option: string, index: number) => {
          const { key, ...tagProps } = getTagProps({ index });
          return <Chip variant="outlined" label={option} key={key} {...tagProps} />;
        }) as ReactNode;
      }}
      renderInput={(params): ReactNode => {
        return (
          <TextField
            {...params}
            error={Boolean(error)}
            helperText={error}
            variant="filled"
            label={label ?? "Titles"}
            placeholder={placeholder || ""}
          />
        );
      }}
    />
  );
}
