import { Box } from "@mui/material";
import React, { FC } from "react";

import { Tooltip } from "components/library/Tooltip";
import { Caption } from "components/library/typography";
import { CandidateBioSource } from "services/openapi";
import { colors } from "styles/theme";

interface DisplayCategoryProps {
  source: CandidateBioSource;
}

export const DisplayCategory: FC<React.PropsWithChildren<DisplayCategoryProps>> = ({ source }) => {
  const { displayAction, displayCategory } = source;

  if (!displayCategory) {
    return <></>;
  }

  const sourceContext = displayAction && displayCategory ? `${displayAction} & - ${displayCategory}` : "";

  return (
    <Tooltip title={sourceContext} placement="bottom">
      <Box sx={{ whiteSpace: "nowrap" }}>
        <Caption color={colors.grayscale.gray500}>{displayCategory}</Caption>
      </Box>
    </Tooltip>
  );
};
