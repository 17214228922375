import React from "react";

import PageHelmet from "components/PageHelmet";
import { useGetUsersClientQuery } from "services/doverapi/endpoints/client/endpoints";
import { useIsFreeCustomer } from "services/doverapi/endpoints/client/hooks";

const FREE_CUSTOMER_UPSELL = "https://www.dover.com/app-embed/free-select-recruiter-6aeff4";
const PAID_NOT_MATCHED_UPSELL = "https://www.dover.com/app-embed/deposit-select-recruiter-7afwe3";
const PAID_MATCHED_UPSELL = "https://www.dover.com/app-embed/paid-select-recruiter-b0fed6";

const RecruitingPartnersPage = (): React.ReactElement => {
  const isFreeCustomer = useIsFreeCustomer();
  const { data: client } = useGetUsersClientQuery();

  const paidCustomerUpsell = client?.csm ? PAID_MATCHED_UPSELL : PAID_NOT_MATCHED_UPSELL;

  const embedUrl = isFreeCustomer ? FREE_CUSTOMER_UPSELL : paidCustomerUpsell;

  return (
    <>
      <PageHelmet title="Dover Recruiting Partners" />
      <iframe
        src={embedUrl}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        title="Recruiting Partners"
      />
    </>
  );
};

export default RecruitingPartnersPage;
