/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ModifiedFilterField,
    ModifiedFilterFieldFromJSON,
    ModifiedFilterFieldFromJSONTyped,
    ModifiedFilterFieldToJSON,
    SingleCandidatePipelineStageFilterField,
    SingleCandidatePipelineStageFilterFieldFromJSON,
    SingleCandidatePipelineStageFilterFieldFromJSONTyped,
    SingleCandidatePipelineStageFilterFieldToJSON,
} from './';

/**
 * 
 * @export
 * @interface CandidateFilter
 */
export interface CandidateFilter {
    /**
     * 
     * @type {Array<SingleCandidatePipelineStageFilterField>}
     * @memberof CandidateFilter
     */
    pipelineStages?: Array<SingleCandidatePipelineStageFilterField>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CandidateFilter
     */
    status?: Array<CandidateFilterStatusEnum>;
    /**
     * 
     * @type {string}
     * @memberof CandidateFilter
     */
    pendingCustomerResponse?: CandidateFilterPendingCustomerResponseEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CandidateFilter
     */
    sourcingContext?: Array<CandidateFilterSourcingContextEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof CandidateFilter
     */
    hasMention?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CandidateFilter
     */
    needsAction?: Array<CandidateFilterNeedsActionEnum>;
    /**
     * 
     * @type {ModifiedFilterField}
     * @memberof CandidateFilter
     */
    modified?: ModifiedFilterField | null;
    /**
     * 
     * @type {string}
     * @memberof CandidateFilter
     */
    referrer?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CandidateFilter
     */
    sourcer?: number | null;
}

/**
* @export
* @enum {string}
*/
export enum CandidateFilterStatusEnum {
    Active = 'ACTIVE',
    Withdrew = 'WITHDREW',
    Hired = 'HIRED',
    Rejected = 'REJECTED',
    Snoozed = 'SNOOZED',
    Ignored = 'IGNORED'
}/**
* @export
* @enum {string}
*/
export enum CandidateFilterPendingCustomerResponseEnum {
    Yes = 'YES',
    No = 'NO'
}/**
* @export
* @enum {string}
*/
export enum CandidateFilterSourcingContextEnum {
    Referral = 'REFERRAL',
    ManuallyAdded = 'MANUALLY_ADDED',
    Outbound = 'OUTBOUND',
    Inbound = 'INBOUND',
    Dtn = 'DTN',
    Agency = 'AGENCY'
}/**
* @export
* @enum {string}
*/
export enum CandidateFilterNeedsActionEnum {
    NoActionRequired = 'NO_ACTION_REQUIRED',
    NeedsAction = 'NEEDS_ACTION'
}

export function CandidateFilterFromJSON(json: any): CandidateFilter {
    return CandidateFilterFromJSONTyped(json, false);
}

export function CandidateFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CandidateFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pipelineStages': !exists(json, 'pipeline_stages') ? undefined : ((json['pipeline_stages'] as Array<any>).map(SingleCandidatePipelineStageFilterFieldFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'pendingCustomerResponse': !exists(json, 'pending_customer_response') ? undefined : json['pending_customer_response'],
        'sourcingContext': !exists(json, 'sourcing_context') ? undefined : json['sourcing_context'],
        'hasMention': !exists(json, 'has_mention') ? undefined : json['has_mention'],
        'needsAction': !exists(json, 'needs_action') ? undefined : json['needs_action'],
        'modified': !exists(json, 'modified') ? undefined : ModifiedFilterFieldFromJSON(json['modified']),
        'referrer': !exists(json, 'referrer') ? undefined : json['referrer'],
        'sourcer': !exists(json, 'sourcer') ? undefined : json['sourcer'],
    };
}

export function CandidateFilterToJSON(value?: CandidateFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pipeline_stages': value.pipelineStages === undefined ? undefined : ((value.pipelineStages as Array<any>).map(SingleCandidatePipelineStageFilterFieldToJSON)),
        'status': value.status,
        'pending_customer_response': value.pendingCustomerResponse,
        'sourcing_context': value.sourcingContext,
        'has_mention': value.hasMention,
        'needs_action': value.needsAction,
        'modified': ModifiedFilterFieldToJSON(value.modified),
        'referrer': value.referrer,
        'sourcer': value.sourcer,
    };
}


