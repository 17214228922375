import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, Stack } from "@mui/material";
import { DataGrid, GridEnrichedColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { skipToken } from "@reduxjs/toolkit/query";
import React, { ReactElement } from "react";

import { ReactComponent as CopyIcon } from "assets/icons/link45deg.svg";
import { InfoTip } from "components/InfoTip";
import ButtonTabs from "components/library/ButtonTabs";
import ThreeDotsMenu from "components/library/ThreeDotsMenu";
import { Tooltip } from "components/library/Tooltip";
import { Body, BodyExtraSmall, BodySmall, Overline } from "components/library/typography";
import DoverLoadingOverlay from "components/loading-overlay";
import { sleep } from "components/utils";
import { useModal } from "GlobalOverlays/atoms";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import { useGetCandidateCountsQuery } from "services/doverapi/endpoints/candidate/pipeline-endpoints";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import { CandidateFilterSourcingContextEnum, CandidateFilterStatusEnum, JobReferrer } from "services/openapi";
import { colors } from "styles/theme";
import { addAgencyModalAtom } from "views/agencies/AddAgencyModal";
import { deactivateAgencyConfirmModalAtom } from "views/agencies/DeactivateAgencyConfirmModal";
import { AgenciesTableWrapper } from "views/agencies/styles";
import { copyAgencyLink } from "views/agencies/utils";
import { useListJobReferrersQuery, usePartialUpdateJobReferrerMutation } from "views/referralsV2/endpoints";
import { referredCandidatesModalAtom } from "views/referralsV2/ReferredCandidatesTable";
import { ReferralCandidateCounts, ReferralStatusType } from "views/referralsV2/ReferrersTable";

const LabeledCount = ({ label, count }: { label: string; count?: number | string }): ReactElement => {
  return (
    <Stack alignItems="flex-start">
      <Body weight="500" color={count === 0 || count === "--" ? colors.grayscale.gray500 : colors.black}>
        {count}
      </Body>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Box width="80px">
          <BodyExtraSmall weight="500" color={colors.grayscale.gray500}>
            {label}
          </BodyExtraSmall>
        </Box>
      </Stack>
    </Stack>
  );
};

export interface AgencyRecruiter {
  id?: string;
  referrerId: string;
  clientId: string;
  agencyName: string;
  agencyId: string;
  name: string;
  email: string;
  fee?: string;
  estimatedCost?: string;
  counts: ReferralCandidateCounts;
  jobId?: string;
  disabled: boolean;
}

const AgencyNameCell = (params: GridRenderCellParams): React.ReactElement => {
  const recruiter = params.row as AgencyRecruiter;

  const [clicked, setClicked] = React.useState(false);

  return (
    <Stack direction="row" alignItems="center" spacing={2} justifyContent={"space-between"} width="100%">
      <BodySmall weight="600">{recruiter.agencyName}</BodySmall>
      <Tooltip
        title={clicked ? "Copied!" : "Copy form link"}
        onClose={(): void => {
          sleep(300).then(() => setClicked(false));
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            cursor: "pointer",
          }}
          spacing={0.5}
          onClick={(e): void => {
            e.stopPropagation();
            setClicked(true);
            copyAgencyLink({ clientId: recruiter.clientId, referrerId: recruiter.referrerId });
          }}
        >
          <CopyIcon />
          <BodySmall color={colors.grayscale.gray600}></BodySmall>
        </Stack>
      </Tooltip>
    </Stack>
  );
};

const RecruiterCell = (params: GridRenderCellParams): React.ReactElement => {
  const recruiter = params.row as AgencyRecruiter;

  return (
    <Stack spacing={0}>
      <BodySmall weight="600">{recruiter.name}</BodySmall>
      <BodySmall weight="400">{recruiter.email}</BodySmall>
    </Stack>
  );
};

const FeeCell = (params: GridRenderCellParams): React.ReactElement => {
  const recruiter = params.row as AgencyRecruiter;
  return <BodySmall>{recruiter.fee ?? "-"}</BodySmall>;
};

const EstimatedCostCell = (params: GridRenderCellParams): React.ReactElement => {
  const recruiter = params.row as AgencyRecruiter;

  return <BodySmall>{recruiter.estimatedCost ?? "-"}</BodySmall>;
};

const ConfigMenu = ({ ar }: { ar: AgencyRecruiter }): React.ReactElement => {
  const [updateJobAgencyReferrer] = usePartialUpdateJobReferrerMutation();
  const { open: openEditModal } = useModal(addAgencyModalAtom);
  const { open: openDeactivateModal } = useModal(deactivateAgencyConfirmModalAtom);

  return (
    <ThreeDotsMenu
      horizontal
      options={
        ar.disabled
          ? [
              {
                label: "Activate",
                cb: (): void => {
                  if (ar.id && ar.jobId)
                    updateJobAgencyReferrer({
                      id: ar.id,
                      data: {
                        disabled: false,
                      },
                      jobId: ar.jobId,
                    });
                },
                component: (
                  <Stack color="#327DCD" direction="row" sx={{ justifyContent: "center" }} spacing={1}>
                    <SyncIcon fontSize="small" />
                    <BodySmall>Activate</BodySmall>
                  </Stack>
                ),
              },
            ]
          : [
              {
                label: "Edit",
                cb: (): void => {
                  openEditModal({
                    agencyRecruiter: ar,
                  });
                },
                component: (
                  <Stack direction="row" sx={{ justifyContent: "center" }} spacing={1}>
                    <ModeEditOutlineOutlinedIcon fontSize="small" />
                    <BodySmall>Edit</BodySmall>
                  </Stack>
                ),
              },
              {
                label: "Deactivate",
                cb: (): void => {
                  if (ar.id && ar.jobId)
                    openDeactivateModal({
                      jobReferrerId: ar.id,
                      jobId: ar.jobId,
                      agencyName: ar.agencyName,
                    });
                },
                component: (
                  <Stack color="#E06363" direction="row" sx={{ justifyContent: "center" }} spacing={1}>
                    <DeleteOutlinedIcon fontSize="small" />
                    <BodySmall color="#E06363">Deactivate</BodySmall>
                  </Stack>
                ),
              },
            ]
      }
    />
  );
};

const CandidatesCell = (params: GridRenderCellParams): React.ReactElement => {
  const recruiter = params.row as AgencyRecruiter;
  const counts = recruiter.counts;

  return (
    <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
      <LabeledCount label={"SUBMITTED"} count={counts.submitted} />
      <LabeledCount label={"ACTIVE"} count={counts.active} />
      <LabeledCount label={"HIRES"} count={counts.hires} />
      <ConfigMenu ar={recruiter} />
    </Stack>
  );
};

const COLUMN_DEF: GridEnrichedColDef[] = [
  {
    field: "agencyName",
    flex: 1,
    headerName: "Agency",
    sortable: false,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <AgencyNameCell {...params} />,
  },
  {
    field: "recruiter",
    flex: 1,
    headerName: "Recruiter",
    sortable: false,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <RecruiterCell {...params} />,
  },
  {
    field: "fee",
    headerName: "Fee",
    width: 100,
    sortable: false,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <FeeCell {...params} />,
  },
  {
    field: "estimatedCost",
    flex: 1,
    headerName: "Estimated Cost",
    sortable: false,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return (
        <Stack direction="row" spacing={1}>
          <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>
          <InfoTip text={"The cost estimate is based on the annual compensation range provided in the Job Posting."} />
        </Stack>
      );
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <EstimatedCostCell {...params} />,
  },
  {
    field: "candidates",
    headerName: "Candidates",
    width: 370,
    sortable: false,
    headerClassName: "h-cell",
    renderHeader: (params): React.ReactElement => {
      return <Overline color={colors.grayscale.gray600}>{params.colDef.headerName}</Overline>;
    },
    renderCell: (params: GridRenderCellParams): React.ReactElement => <CandidatesCell {...params} />,
  },
];

export const AgenciesTable = (): React.ReactElement => {
  const [page, setPage] = React.useState(0);
  const [showDisabled, setShowDisabled] = React.useState(false);

  const jobId = useJobIdFromUrl();
  const clientId = useGetClientId();
  const { data, isFetching } = useListJobReferrersQuery(
    jobId ? { jobId, offset: page * 10, limit: 10, isAgencyRecruiter: true, isDisabled: showDisabled } : skipToken
  );

  const { data: counts, isFetching: isFetchingCandidates } = useGetCandidateCountsQuery(
    !jobId || isFetching
      ? skipToken
      : {
          args: {
            jobId,
            data: {
              countFilters: [
                ...(data?.results.map(r => {
                  return {
                    name: `${ReferralStatusType.SUBMITTED}|${r.referrerId}`,
                    filters: {
                      sourcingContext: [CandidateFilterSourcingContextEnum.Agency],
                      referrer: r.referrerId,
                    },
                  };
                }) || []),
                ...(data?.results.map(r => {
                  return {
                    name: `${ReferralStatusType.ACTIVE}|${r.referrerId}`,
                    filters: {
                      status: [CandidateFilterStatusEnum.Active],
                      sourcingContext: [CandidateFilterSourcingContextEnum.Agency],
                      referrer: r.referrerId,
                    },
                  };
                }) || []),
                ...(data?.results.map(r => {
                  return {
                    name: `${ReferralStatusType.HIRES}|${r.referrerId}`,
                    filters: {
                      status: [CandidateFilterStatusEnum.Hired],
                      sourcingContext: [CandidateFilterSourcingContextEnum.Agency],
                      referrer: r.referrerId,
                    },
                  };
                }) || []),
              ],
            },
          },
        }
  );

  const countsByReferrer = counts?.reduce((acc, count) => {
    const [type, referrerId] = count.name.split("|");

    if (referrerId) {
      acc[referrerId] = acc[referrerId] || { submitted: 0, active: 0, hires: 0 };
      if (type === ReferralStatusType.SUBMITTED) {
        acc[referrerId].submitted = count.count;
      } else if (type === ReferralStatusType.ACTIVE) {
        acc[referrerId].active = count.count;
      } else if (type === ReferralStatusType.HIRES) {
        acc[referrerId].hires = count.count;
      }
    }
    return acc;
  }, {} as { [key: string]: ReferralCandidateCounts });

  const rows: AgencyRecruiter[] = (
    data?.results?.map((referrer: JobReferrer) => {
      return {
        id: referrer.id,
        referrerId: referrer.referrerId,
        clientId: clientId ?? "",
        agencyName: referrer.agencyName || "",
        agencyId: referrer.agencyId || "",
        disabled: referrer.disabled || false,
        name: referrer.name,
        email: referrer.email,
        fee: referrer.fee,
        estimatedCost: referrer.estimatedCost,
        counts: countsByReferrer?.[referrer.referrerId ?? ""] ?? { submitted: 0, active: 0, hires: 0 },
        jobId,
      };
    }) || []
  ).sort((a, b) => b.counts.submitted - a.counts.submitted);

  const { open } = useModal(referredCandidatesModalAtom);

  if (isFetching || isFetchingCandidates) {
    return <DoverLoadingOverlay active />;
  }

  return (
    <Stack spacing={1}>
      <Box width={180}>
        <ButtonTabs
          tabs={[
            { label: "Active", cb: (): void => setShowDisabled(false) },
            { label: "Deactivated", cb: (): void => setShowDisabled(true) },
          ]}
        />
      </Box>
      <AgenciesTableWrapper>
        <DataGrid
          autoHeight
          headerHeight={40}
          rowHeight={64}
          rows={rows}
          rowCount={data?.count || 0}
          hideFooter={!!((data?.count || 0) <= 10)}
          columns={COLUMN_DEF}
          pagination
          paginationMode="server"
          page={page}
          pageSize={10}
          onPageChange={setPage}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          localeText={{ noRowsLabel: "Add an agency to track candidates" }}
          onRowClick={(params: GridRowParams): void => {
            const recruiter = params.row as AgencyRecruiter;
            open({
              displayValue: recruiter.name,
              referrerId: recruiter.referrerId ?? "",
              isAgency: true,
            });
          }}
        />
      </AgenciesTableWrapper>
    </Stack>
  );
};
