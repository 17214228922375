import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";

interface ThreeDotsMenuOption {
  label: string;
  cb?: () => void;
  component?: React.ReactElement;
}

interface ThreeDotsMenuProps {
  options: ThreeDotsMenuOption[];
  horizontal?: boolean;
}

export default function ThreeDotsMenu({ options, horizontal }: ThreeDotsMenuProps): React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        color="inherit"
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ minWidth: 0, p: 1 }}
      >
        <MoreVertIcon
          sx={{
            transform: horizontal ? "rotate(90deg)" : "rotate(0deg)",
          }}
        />
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem
            key={option.label}
            onClick={(): void => {
              if (option.cb) option.cb();
              handleClose();
            }}
          >
            {option.component ? option.component : option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
