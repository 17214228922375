/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JobReferrer
 */
export interface JobReferrer {
    /**
     * 
     * @type {string}
     * @memberof JobReferrer
     */
    readonly id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JobReferrer
     */
    disabled?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof JobReferrer
     */
    referrerId: string;
    /**
     * 
     * @type {string}
     * @memberof JobReferrer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof JobReferrer
     */
    email: string;
    /**
     * The percentage fee that the referrer charges for a referral. Visible regardless of user permissions for job compensation.
     * @type {string}
     * @memberof JobReferrer
     */
    readonly fee?: string;
    /**
     * The estimated cost of a referral, in actual currency. Always visible if the referrer charges a flat rate. For percentages, surface a figure if the user has permissions to view job compensation.
     * @type {string}
     * @memberof JobReferrer
     */
    readonly estimatedCost?: string;
    /**
     * 
     * @type {string}
     * @memberof JobReferrer
     */
    agencyName?: string;
    /**
     * 
     * @type {string}
     * @memberof JobReferrer
     */
    agencyId?: string;
}

export function JobReferrerFromJSON(json: any): JobReferrer {
    return JobReferrerFromJSONTyped(json, false);
}

export function JobReferrerFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobReferrer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
        'referrerId': json['referrer_id'],
        'name': json['name'],
        'email': json['email'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'estimatedCost': !exists(json, 'estimated_cost') ? undefined : json['estimated_cost'],
        'agencyName': !exists(json, 'agency_name') ? undefined : json['agency_name'],
        'agencyId': !exists(json, 'agency_id') ? undefined : json['agency_id'],
    };
}

export function JobReferrerToJSON(value?: JobReferrer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'disabled': value.disabled,
        'referrer_id': value.referrerId,
        'name': value.name,
        'email': value.email,
        'agency_name': value.agencyName,
        'agency_id': value.agencyId,
    };
}


