import { zodResolver } from "@hookform/resolvers/zod";
import { Box, FormControl, FormHelperText, MenuItem, Select, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useAtomValue } from "jotai";
import React, { useState } from "react";
import { FormProvider, useController, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { StringParam, useQueryParam } from "use-query-params";

import { accountDetailsFormSchema, AccountDetailsFormSchemaType } from "App/components/Onboarding/types";
import { DoverLoginSource, doverLoginSourceAtom } from "App/routing/atoms";
import { CURRENT_FLOW_ID_PARAM_NAME } from "App/routing/constants";
import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { ReactComponent as DownCaretSVG } from "assets/icons/caret-down-black.svg";
import { ReactComponent as Logos } from "assets/images/onboarding-logos.svg";
import DoverLogo from "components/icons/DoverLogo";
import { Button, ButtonVariant } from "components/library/Button";
import { ButtonRadio } from "components/library/ButtonRadio";
import { TextField } from "components/library/TextField";
import { Body, BodySmall, TitleLarge } from "components/library/typography";
import OnboardingGraphic from "components/onboarding/OnboardingGraphic";
import { useGetUsersClientQuery, usePartialUpdateClientMutation } from "services/doverapi/endpoints/client/endpoints";
import { useGetAuthedUserInfoQuery, usePartialUpdateProUserMutation } from "services/doverapi/endpoints/proUser";
import { useMarkOnboardingCompleteMutation } from "services/doverapi/endpoints/user-onboarding-flow/userOnboardingFlow";
import { colors, screenSizesNumbers } from "styles/theme";
import { showErrorToast } from "utils/showToast";

export enum CalendlyRole {
  Founder = "Founder/Owner",
  Recruiter = "Recruiter/Recruiting Leader",
  Finance = "Finance/Operations/Chief of Staff",
  HiringManager = "Executive/Hiring Manager",
  Other = "Other",
}

export enum ReferralSource {
  LinkedIn = "LinkedIn",
  Referral = "Referred by a Dover customer",
  YC = "YC",
  Google = "Google",
  Other = "Other",
}

export enum Region {
  USACanada = "USA/Canada",
  Europe = "Europe",
  Asia = "Asia",
  SouthAmerica = "South America",
  Other = "Other",
}

const DownArrowIcon = (
  <Stack justifyContent={"center"}>
    <DownCaretSVG width="30px" />
  </Stack>
);

const AccountDetailsInnerForm = (): React.ReactElement => {
  const [page, setPage] = useState<number>(0);
  const doverLoginSource = useAtomValue(doverLoginSourceAtom);

  const navigate = useNavigate();
  const [currentFlowId] = useQueryParam(CURRENT_FLOW_ID_PARAM_NAME, StringParam);

  const { data: client, isLoading: clientLoading } = useGetUsersClientQuery();
  const { data: userInfo, isLoading: userInfoLoading } = useGetAuthedUserInfoQuery();
  const [markOnboardingComplete] = useMarkOnboardingCompleteMutation();

  const [partialUpdateProUser, { isLoading: updatingProUser }] = usePartialUpdateProUserMutation();
  const [partialUpdateClient, { isLoading: updatingClient }] = usePartialUpdateClientMutation();

  const userAndCompanyOnboardingFormMethods = useForm<AccountDetailsFormSchemaType>({
    resolver: zodResolver(accountDetailsFormSchema),
  });
  const {
    register: userAndCompanyOnboardingRegister,
    handleSubmit: handleUserAndCompanyOnboardingSubmit,
    formState: { errors: userAndCompanyOnboardingErrors },
    setValue: setUserAndCompanyOnboardingFormValue,
    control: userAndCompanyOnboardingControl,
    clearErrors,
    trigger,
  } = userAndCompanyOnboardingFormMethods;

  const { field: calendlyRoleField } = useController({
    name: "calendlyRole",
    control: userAndCompanyOnboardingControl,
    defaultValue: "",
  });

  const { field: referralSourceField } = useController({
    name: "referralSource",
    control: userAndCompanyOnboardingControl,
    defaultValue: "",
  });

  const { field: lookingToGetField } = useController({
    name: "lookingToGet",
    control: userAndCompanyOnboardingControl,
    defaultValue: "",
  });

  const { field: regionField } = useController({
    name: "region",
    control: userAndCompanyOnboardingControl,
    defaultValue: "",
  });

  const { field: isAgencyField } = useController({
    name: "isAgency",
    control: userAndCompanyOnboardingControl,
  });

  const onUserAndCompanyOnboardingSubmit = React.useCallback(
    async (data: AccountDetailsFormSchemaType) => {
      const trySubmitUserAndCompanyOnboarding = async (): Promise<boolean> => {
        if (client === undefined || userInfo === undefined || userInfo.id === undefined) {
          return false;
        }

        return await Promise.all([
          partialUpdateProUser({
            flowId: currentFlowId,
            user: userInfo.id.toString(),
            data: {
              firstName: data.firstName,
              lastName: data.lastName,
              roleTitleData: {
                ...userInfo.roleTitleData,
                title: data.title,
                calendlyRole: data.calendlyRole,
                referralSource: data.referralSource,
                lookingToGet: data.lookingToGet,
              },
            },
          }).unwrap(),
          partialUpdateClient({
            id: client.id!,
            updatedClient: {
              name: data.companyName,
              realName: data.companyName,
              isAgency: data.isAgency === "true",
              region: data.region,
            },
          }).unwrap(),
          // not important anymore for people to select a feature, can always mark onboarding complete
          // after this initial form and first time they see the home page.
          await markOnboardingComplete(true).unwrap(),
        ])
          .then(() => true)
          .catch(() => false);
      };

      const success = await trySubmitUserAndCompanyOnboarding();
      if (!success) {
        showErrorToast("Company name already exists. Please update and try again.");
        return;
      }

      // After successful onboarding form submission:
      if (doverLoginSource === DoverLoginSource.Extension) {
        // Extension users have special handling - they need to go through schedule onboarding
        // which has special handling for extension users
        navigate({ pathname: APP_ROUTE_PATHS.onboarding.scheduleOnboarding() });
      } else {
        // All other users see recruiting partners splash screen
        // This includes agencies, subsequent users from same company, and all regions
        navigate({ pathname: APP_ROUTE_PATHS.onboarding.recruitingPartners() });
      }
    },
    [
      navigate,
      client,
      userInfo,
      partialUpdateProUser,
      currentFlowId,
      partialUpdateClient,
      markOnboardingComplete,
      doverLoginSource,
    ]
  );

  // Set initial form values

  React.useEffect(() => {
    if (client?.name) {
      setUserAndCompanyOnboardingFormValue("companyName", client.name);
    }
    if (client?.isAgency !== undefined) {
      setUserAndCompanyOnboardingFormValue("isAgency", client.isAgency ? "true" : "false");
    }
    if (client?.region) {
      setUserAndCompanyOnboardingFormValue("region", client.region);
    }
  }, [client, setUserAndCompanyOnboardingFormValue]);

  React.useEffect(() => {
    if (userInfo) {
      setUserAndCompanyOnboardingFormValue("firstName", userInfo.firstName!);
      setUserAndCompanyOnboardingFormValue("lastName", userInfo.lastName!);

      if (userInfo.roleTitleData) {
        const roleTitleData = userInfo.roleTitleData as any;
        setUserAndCompanyOnboardingFormValue("title", roleTitleData["title"]);
        setUserAndCompanyOnboardingFormValue("calendlyRole", roleTitleData["calendlyRole"] ?? "");
        setUserAndCompanyOnboardingFormValue("referralSource", roleTitleData["referralSource"] ?? "");
        setUserAndCompanyOnboardingFormValue("lookingToGet", roleTitleData["lookingToGet"] ?? "");
      }
    }
  }, [userInfo, setUserAndCompanyOnboardingFormValue]);

  const entireFormValid = async (): Promise<boolean> => {
    const isValid = await trigger([
      // page 1
      "firstName",
      "lastName",
      "calendlyRole",
      "title",
      "referralSource",
      // page 2
      "companyName",
      "region",
      "isAgency",
    ]);
    return isValid;
  };

  const firstPageValid = async (): Promise<boolean> => {
    const isValid = await trigger(["firstName", "lastName", "calendlyRole", "title", "referralSource"]);
    return isValid;
  };

  const goToNextPage = async (): Promise<void> => {
    if (await firstPageValid()) {
      clearErrors();
      setPage(1);
    }
  };

  return (
    <Stack
      spacing={2}
      width="50vw"
      height="100%"
      position="relative"
      maxWidth="100%"
      overflow="scroll"
      sx={{
        "&:-webkit-scrollbar": "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "&-ms-overflow-style:": {
          display: "none",
        },
      }}
    >
      <FormProvider {...userAndCompanyOnboardingFormMethods}>
        <AnimateLeftPanel className={page === 0 ? "show" : "hide"}>
          <Stack spacing={2} p={3}>
            <TitleLarge>Tell us a little about yourself</TitleLarge>

            <Stack direction="row">
              <Box flexBasis="50%">
                <TextField
                  title={`First name`}
                  required={true}
                  inputProps={{ ...userAndCompanyOnboardingRegister("firstName") }}
                  errorMessage={userAndCompanyOnboardingErrors.firstName?.message}
                  error={!!userAndCompanyOnboardingErrors.firstName}
                  autoFocus
                />
              </Box>
              <Box ml={2} flexBasis="50%">
                <TextField
                  title={`Last name`}
                  required={true}
                  inputProps={{ ...userAndCompanyOnboardingRegister("lastName") }}
                  errorMessage={userAndCompanyOnboardingErrors.lastName?.message}
                  error={!!userAndCompanyOnboardingErrors.lastName}
                />
              </Box>
            </Stack>
            <Select
              sx={{ ".MuiSelect-select": { paddingRight: "0px !important", backgroundColor: "white !important" } }}
              variant="standard"
              disableUnderline
              value={calendlyRoleField.value}
              displayEmpty
              IconComponent={(): null => null}
              onChange={(e): void => {
                calendlyRoleField.onChange(e.target.value as string);
              }}
              renderValue={(value: string): React.ReactNode => {
                return (
                  <TextField
                    title={"What is your role?"}
                    required={!!userAndCompanyOnboardingErrors.calendlyRole}
                    text={value}
                    endAdornment={DownArrowIcon}
                  />
                );
              }}
            >
              {Object.values(CalendlyRole).map(option => {
                return (
                  <MenuItem value={option}>
                    <BodySmall>{option}</BodySmall>
                  </MenuItem>
                );
              })}
            </Select>
            <TextField
              title={"What is your title?"}
              required={true}
              placeholderText={"CEO, Software Engineer, Engineering Manager"}
              inputProps={{ ...userAndCompanyOnboardingRegister("title") }}
              errorMessage={userAndCompanyOnboardingErrors.title?.message}
              error={!!userAndCompanyOnboardingErrors.title}
            />
            <TextField
              title={"How did you hear about us?"}
              required={!!userAndCompanyOnboardingErrors.referralSource}
              text={referralSourceField.value}
              onTextUpdated={(text: string): void => {
                referralSourceField.onChange(text);
              }}
            />
            <TextField
              title={"What are you looking to get out of Dover?"}
              text={lookingToGetField.value}
              onTextUpdated={(text: string): void => {
                lookingToGetField.onChange(text);
              }}
            />

            <Box display="flex">
              <Button
                variant={ButtonVariant.Primary}
                loading={clientLoading || userInfoLoading || updatingProUser || updatingClient}
                onClick={async (): Promise<void> => {
                  const valid = await entireFormValid();
                  if (valid) {
                    handleUserAndCompanyOnboardingSubmit(onUserAndCompanyOnboardingSubmit)();
                  } else {
                    goToNextPage();
                  }
                }}
              >
                Continue
              </Button>
            </Box>
            <Stack pt={2} alignItems="center">
              <Logos />
            </Stack>
          </Stack>
        </AnimateLeftPanel>

        <AnimateRightPanel className={page === 1 ? "show" : "hide"}>
          <Stack spacing={2} p={3}>
            <TitleLarge>Just a few more things...</TitleLarge>
            <TextField
              title={"Company name"}
              required={true}
              placeholderText={"Acme Corporation"}
              inputProps={{ ...userAndCompanyOnboardingRegister("companyName") }}
              errorMessage={userAndCompanyOnboardingErrors.companyName?.message}
              error={!!userAndCompanyOnboardingErrors.companyName}
            />
            <Select
              sx={{ ".MuiSelect-select": { paddingRight: "0px !important", backgroundColor: "white !important" } }}
              variant="standard"
              disableUnderline
              displayEmpty
              value={regionField.value}
              IconComponent={(): null => null}
              onChange={(e): void => {
                regionField.onChange(e.target.value as string);
              }}
              renderValue={(value: string): React.ReactNode => {
                return (
                  <TextField
                    title={"Where is your company headquartered?"}
                    required={!!userAndCompanyOnboardingErrors.region}
                    text={value}
                    endAdornment={DownArrowIcon}
                  />
                );
              }}
            >
              {Object.values(Region).map(option => {
                return (
                  <MenuItem value={option}>
                    <BodySmall>{option}</BodySmall>
                  </MenuItem>
                );
              })}
            </Select>
            <FormControl variant="outlined" size="small" fullWidth error={!!userAndCompanyOnboardingErrors.isAgency}>
              <Stack spacing={1}>
                <Body weight="500">Is your company a staffing agency?</Body>
                <Stack direction="row" spacing={1}>
                  <ButtonRadio
                    active={isAgencyField.value === "true"}
                    onClick={(): void => isAgencyField.onChange("true")}
                    label="Yes"
                  />
                  <ButtonRadio
                    active={isAgencyField.value === "false"}
                    onClick={(): void => isAgencyField.onChange("false")}
                    label={"No"}
                  />
                </Stack>
                {/* TODO: see if we can still use error message here but make it say Required instead of hardcoding */}
                {userAndCompanyOnboardingErrors.isAgency?.message && <FormHelperText>Required</FormHelperText>}
              </Stack>
            </FormControl>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant={ButtonVariant.Secondary} onClick={(): void => setPage(0)}>
                Back
              </Button>
              <Button
                variant={ButtonVariant.Primary}
                onClick={handleUserAndCompanyOnboardingSubmit(onUserAndCompanyOnboardingSubmit)}
                loading={clientLoading || userInfoLoading || updatingProUser || updatingClient}
              >
                Continue
              </Button>
            </Stack>
          </Stack>
        </AnimateRightPanel>
      </FormProvider>
    </Stack>
  );
};

export const AccountDetailsFormV2 = (): React.ReactElement => {
  const muiTheme = useTheme();
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.tablet));

  return (
    <Stack direction="row" height="100%" position="relative">
      <Stack>
        <Box width="100%" p="24px">
          <DoverLogo />
        </Box>
        <Stack flexBasis="100%" overflow="auto">
          <AccountDetailsInnerForm />
        </Stack>
      </Stack>

      {!isSmallScreen && (
        <Stack
          sx={{
            height: "100%",
            backgroundColor: colors.grayscale.gray100,
            "&:-webkit-scrollbar": "none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "&-ms-overflow-style:": {
              display: "none",
            },
          }}
          display="flex"
          flexBasis="50%"
          justifyContent="start"
          p={8}
          alignItems="center"
          overflow="scroll"
        >
          <OnboardingGraphic />
        </Stack>
      )}
    </Stack>
  );
};

const AnimateBase = styled.div`
  transition: all 0.35s ease;
  position: absolute;
  width: 100%;
  top: 50vh;
  transform: translateY(-50vh);

  &.show {
    opacity: 1;
  }

  &.hide {
    opacity: 0;
  }
`;

const AnimateLeftPanel = styled(AnimateBase)`
  &.show {
    left: 0;
  }

  &.hide {
    left: -100%;
  }
`;

const AnimateRightPanel = styled(AnimateBase)`
  &.show {
    right: 0;
  }

  &.hide {
    right: -100%;
  }
`;
