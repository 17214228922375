import { z } from "zod";

export const ApplicationReviewSchema = z.object({
  id: z.string().optional(),
  resumeLink: z.string().optional(),
  resumeLinkExpirationDate: z.date().optional(),
  computedTotalYoe: z.number().optional(),
  computedTitleRaw1: z.string().optional(),
  computedTitleRaw2: z.string().optional(),
  computedTitleT21: z.string().optional(),
  computedTitleT22: z.string().optional(),
  computedJobCategory: z.string().optional(),
  annotatedTotalYoe: z.number(),
  annotatedTitleRaw1: z.string(),
  annotatedTitleRaw2: z.string(),
  annotatedTitleT21: z.string(),
  annotatedTitleT22: z.string(),
  reviewed: z.boolean().optional(),
  computedLocation: z.string().optional(),
  annotatedLocation: z.string(),
  annotatedJobCategory: z.string(),
  annotatedHasSeniority: z.boolean(),
  annotatedHasTargetLocations: z.boolean(),
  computedHasSeniority: z.boolean().optional(),
  computedHasTargetLocations: z.boolean().optional(),
});

export type ApplicationReviewData = z.infer<typeof ApplicationReviewSchema>;

export function generateRandomEntries(total: number = 10, reviewed: boolean = false): ApplicationReviewData[] {
  const randomA = Math.random() * 10 + 1;
  const randomB = Math.random() * 10 + 1;

  return [...Array(total).keys()].map(
    (): ApplicationReviewData => {
      return {
        id: `application-${Math.random() * 10000 + 100}`,
        resumeLink: "",
        resumeLinkExpirationDate: new Date(),
        computedTotalYoe: Math.random() * 5 + randomA + 1,
        computedTitleRaw1: "",
        computedTitleRaw2: "",
        computedTitleT21: "",
        computedTitleT22: "",
        annotatedTotalYoe: Math.random() * 5 + randomB + 1,
        annotatedTitleRaw1: "",
        annotatedTitleRaw2: "",
        annotatedTitleT21: "",
        annotatedTitleT22: "",
        computedLocation: "",
        annotatedLocation: "",
        annotatedJobCategory: "",
        annotatedHasTargetLocations: false,
        annotatedHasSeniority: false,
        computedHasTargetLocations: false,
        computedHasSeniority: false,
        reviewed,
      };
    }
  );
}
