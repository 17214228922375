import { zodResolver } from "@hookform/resolvers/zod";
import { Box, CircularProgress, Stack, TextFieldProps } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React, { useMemo, useEffect } from "react";
import { Controller, FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";

import { APP_ROUTE_PATHS } from "App/routing/route-path-constants";
import { AccountSettingsSectionType } from "App/routing/types";
import { ReactComponent as LockIconSVG } from "assets/icons/lock.svg";
import Banner from "components/Banner";
import { CAMPAIGN_MESSAGE_LIMIT } from "components/constants";
import { CAMPAIGN_VARIABLE_KEYS } from "components/dover/AddCandidate/CampaignMessageEditor";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { BodySmall, Subtitle1 } from "components/library/typography";
import CampaignMessageEditor from "components/outreach-configuration/form/CampaignMessageEditorField";
import {
  DEFAULT_CAMPAIGN_MESSAGE_DELAY,
  SECONDS_IN_DAY,
  THREE_DAYS_IN_SECONDS,
} from "components/outreach-configuration/form/constants";
import { CampaignsFormSchemaType, campaignsFormSchema } from "components/outreach-configuration/form/types";
import { StyledTextField } from "components/StyledMuiComponents";
import { VariableSelector } from "components/VariableSelector";
import { useModal } from "GlobalOverlays/atoms";
import { useJobId } from "hooks/useJobIdFromUrl";
import {
  useGetCampaignQuery,
  useListCampaignsQuery,
  usePartialUpdateCampaignMutation,
} from "services/doverapi/endpoints/campaign/endpoints";
import { selectFromListCampaignsQueryResult } from "services/doverapi/endpoints/campaign/selector-utils";
import { useGetDoverPlan } from "services/doverapi/endpoints/client/hooks";
import { useGetProUserQuery } from "services/doverapi/endpoints/proUser";
import {
  Campaign,
  CampaignCampaignStateEnum,
  CampaignMessageCampaignMessageStateEnum,
  ClientDoverPlanEnum,
  CreateCampaignMessageCampaignMessageStateEnum,
  ListCampaignEmailSenderOptionEnum,
} from "services/openapi";
import { colors } from "styles/theme";
import { InternalLink } from "styles/typography";
import { getEmailAliasName } from "utils/getEmailAliasName";
import { CampaignDrawerAtom } from "views/job/JobSetup/steps/CampaignVariants/CampaignDrawer";
import DeleteCampaignButton from "views/job/JobSetup/steps/CampaignVariants/components/DeleteCampaignButton";
import {
  useIsCampaignEmailSenderAuthed,
  useValidateCampaignMessages,
} from "views/job/JobSetup/steps/CampaignVariants/hooks";

/*************************************************
 * Subcomponents
 ************************************************/

const AuthGmailBanner = ({ campaign }: { campaign: Campaign }): React.ReactElement => {
  const { data: userDefinedSenderUser } = useGetProUserQuery((campaign.userDefinedSenderUser as unknown) as string);

  const { isAuthed: isCampaignEmailSenderAuthed } = useIsCampaignEmailSenderAuthed({
    userDefinedSenderUser: campaign.userDefinedSenderUser,
    emailSenderOption: campaign.emailSenderOption as ListCampaignEmailSenderOptionEnum,
    emailAliasEmail: campaign.emailAlias ?? undefined,
    emailAliasGmailCredentialAuthState: campaign.emailAliasInfo?.gmailCredentialAuthState,
  });

  if (isCampaignEmailSenderAuthed) {
    return <></>;
  }

  const name = getEmailAliasName(
    userDefinedSenderUser,
    campaign.emailSenderOption as ListCampaignEmailSenderOptionEnum,
    campaign.emailAliasInfo
  );

  return (
    <Box onClick={(e): void => e.stopPropagation()}>
      <Banner type="warning" alignItems="center" variant="filled">
        <>
          <BodySmall inline>
            {`To send emails from ${campaign.emailAliasInfo?.email} please have ${name} `}
            <InternalLink
              target="_blank"
              $variant="secondary"
              to={APP_ROUTE_PATHS.accountSetup(AccountSettingsSectionType.EMAIL_CONNECTION)}
            >{` connect their Gmail account`}</InternalLink>
          </BodySmall>
        </>
      </Banner>
    </Box>
  );
};

const CampaignEmailSender = ({ campaign }: { campaign: Campaign }): React.ReactElement => {
  const { data: userDefinedSenderUser, isFetching: isFetchingProUser } = useGetProUserQuery(
    campaign.userDefinedSenderUser ? ((campaign.userDefinedSenderUser as unknown) as string) : skipToken
  );

  const emailSenderDisplay = campaign.emailAliasInfo?.email ?? userDefinedSenderUser?.fullName ?? "Not set";

  return (
    <Stack p={2} spacing={1} sx={{ borderRadius: "4px", border: `1px solid ${colors.grayscale.gray300}` }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <BodySmall color={colors.grayscale.gray500}>From:</BodySmall>
        {isFetchingProUser ? (
          <BodySmall color={colors.grayscale.gray500}>Loading...</BodySmall>
        ) : (
          <Stack direction="row" spacing={1} alignItems="center">
            <BodySmall>{emailSenderDisplay}</BodySmall>
            <Tooltip title="Please create a new campaign to send emails from a different sender.">
              <LockIconSVG />
            </Tooltip>
          </Stack>
        )}
      </Stack>
      <AuthGmailBanner campaign={campaign} />
    </Stack>
  );
};

interface ControlledTextFieldProps extends Pick<TextFieldProps, "placeholder" | "error" | "helperText"> {
  name: "name";
}

const ControlledTextField = ({
  name,
  placeholder,
  error,
  helperText,
}: ControlledTextFieldProps): React.ReactElement => {
  const { control } = useFormContext<CampaignsFormSchemaType>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }): React.ReactElement => (
        <StyledTextField
          placeholder={placeholder}
          {...field}
          size="small"
          fullWidth
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

const StatusBanner = (campaign: Campaign): React.ReactElement => {
  if (campaign.campaignState === CampaignCampaignStateEnum.Active) {
    return (
      <Banner type="success" alignItems="center" variant="filled">
        <BodySmall>{`This campaign is active. Save to publish changes.`}</BodySmall>
      </Banner>
    );
  }

  return (
    <Banner type="critical" alignItems="center" variant="filled">
      <BodySmall>{`This campaign is inactive.`}</BodySmall>
    </Banner>
  );
};

/*************************************************
 * Data Loading Wrapper
 *
 * This component is responsible for fetching the campaign data and passing it to the CampaignEditor component.
 * ************************************************/

const EditCampaignWrapper: React.FC<React.PropsWithChildren<{
  campaignId: string;
  onFormDirty: (isDirty: boolean) => void;
}>> = ({ campaignId, onFormDirty }) => {
  const [jobId] = useJobId();

  const { data: campaign, isFetching: isLoadingCampaign, isSuccess } = useGetCampaignQuery(campaignId ?? skipToken);
  const { numCampaigns, isLoadingCampaignCount } = useListCampaignsQuery(jobId ? { jobId } : skipToken, {
    selectFromResult: result => {
      const { allCampaigns, isFetching } = selectFromListCampaignsQueryResult(result);
      return { numCampaigns: allCampaigns?.length, isLoadingCampaignCount: isFetching };
    },
  });

  const defaultFormValues = useMemo(() => {
    if (!campaign) {
      return { campaignEditors: [], isDirty: false };
    }
    return {
      name: campaign.name,
      active: campaign.campaignState === CampaignCampaignStateEnum.Active,
      allowFollowUpsPostDeactivation: false,
      campaignEditors: (campaign?.threadMessages ?? []).map(message => ({
        rawEditorState: {
          subjectHtml: message.subjectTemplate ?? undefined,
          bodyHtml: message.bodyTemplate ?? undefined,
        },
        id: message.messageId,
        enabled: message.campaignMessageState === CampaignMessageCampaignMessageStateEnum.Active,
        minMessageDelay: message.minMessageDelay
          ? parseFloat(message.minMessageDelay.toString()) * SECONDS_IN_DAY
          : DEFAULT_CAMPAIGN_MESSAGE_DELAY,
      })),
      isDirty: false,
    };
  }, [campaign]);

  if (isLoadingCampaign || isLoadingCampaignCount) {
    return (
      <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (!campaign && isSuccess) {
    return <Box p={2}>Could not find campaign</Box>;
  }

  const showDeleteButton = !!numCampaigns && numCampaigns > 1;

  return (
    <CampaignEditor
      campaign={campaign!}
      defaultFormValues={defaultFormValues}
      showDeleteButton={showDeleteButton}
      onFormDirty={onFormDirty}
    />
  );
};

/*************************************************
 * Main Component
 ************************************************/

const CampaignEditor: React.FC<React.PropsWithChildren<{
  campaign: Campaign;
  defaultFormValues: CampaignsFormSchemaType;
  showDeleteButton: boolean;
  onFormDirty: (isDirty: boolean) => void;
}>> = ({ campaign, defaultFormValues, showDeleteButton, onFormDirty }) => {
  const { close } = useModal(CampaignDrawerAtom);
  const [jobId] = useJobId();

  // form state
  const formMethods = useForm<CampaignsFormSchemaType>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(campaignsFormSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
  } = formMethods;

  const watchedEditors = watch("campaignEditors");
  const campaignEditors = useWatch({ control, name: "campaignEditors" });

  // Track if email content has changed
  useEffect(() => {
    const hasChanged = watchedEditors.some((editor, index) => {
      const defaultEditor = defaultFormValues.campaignEditors[index];
      if (!defaultEditor) return true;

      return (
        editor.rawEditorState.bodyHtml !== defaultEditor.rawEditorState.bodyHtml ||
        editor.rawEditorState.subjectHtml !== defaultEditor.rawEditorState.subjectHtml
      );
    });

    // Update form's isDirty state if email content changed
    if (hasChanged && !isDirty) {
      formMethods.trigger(); // This will update isDirty state
    }

    // Notify parent of form state changes
    onFormDirty(isDirty || hasChanged);
  }, [watchedEditors, defaultFormValues.campaignEditors, isDirty, formMethods, onFormDirty]);

  const doverPlan = useGetDoverPlan();

  const { data: userDefinedSenderUser } = useGetProUserQuery(
    campaign.userDefinedSenderUser ? ((campaign.userDefinedSenderUser as unknown) as string) : skipToken
  );

  const [partialUpdateCampaign, { isLoading: isUpdatingCampaign }] = usePartialUpdateCampaignMutation();

  // This handles an edge case where the user selects on a campaign with a user defined sender user
  // then selects another campaign without a user defined sender user.
  // We have to ensure that the selectedCampaign has a user defined sender user, otherwise the
  // userDefinedSenderUser variable from the getProUserQuery will not update because the skipToken is used
  // and userDefinedSenderUser will still reference the one from the previous campaign.
  const senderUser = campaign.userDefinedSenderUser ? userDefinedSenderUser : undefined;

  const messageLimitReached = useMemo(
    () => campaign && campaign.threadMessages && campaign.threadMessages.length >= CAMPAIGN_MESSAGE_LIMIT,
    [campaign]
  );

  const { errorMessage } = useValidateCampaignMessages({
    campaignMessages: campaignEditors,
    senderUser,
  });

  const handleAddNewMessage = async (): Promise<void> => {
    if (jobId && campaign.id) {
      const lastMessage = campaign?.threadMessages
        ? campaign?.threadMessages[campaign?.threadMessages?.length - 1]
        : null;

      await partialUpdateCampaign({
        id: campaign.id,
        jobId: jobId,
        updatedCampaign: {
          newMessages: [
            {
              campaignMessageState: CreateCampaignMessageCampaignMessageStateEnum.Inactive,
              minMessageDelay: THREE_DAYS_IN_SECONDS,
              prevMessage: lastMessage ? lastMessage.messageId : null,
            },
          ],
          name: campaign.name!,
        },
      });
    }
  };

  const handleUpdateCampaign = async (data: CampaignsFormSchemaType): Promise<void> => {
    if (jobId && campaign.id) {
      const updatedMessages = data.campaignEditors.map(editor => {
        return {
          messageId: editor.id,
          campaignMessageState: editor.enabled
            ? CampaignMessageCampaignMessageStateEnum.Active
            : CampaignMessageCampaignMessageStateEnum.Inactive,
          minMessageDelay: editor.minMessageDelay,
          rawEditorState: editor.rawEditorState,
        };
      });

      await partialUpdateCampaign({
        id: campaign.id,
        jobId: jobId!,
        updatedCampaign: {
          campaignState: data.active ? CampaignCampaignStateEnum.Active : CampaignCampaignStateEnum.Inactive,
          name: data.name ?? campaign.name,
          updatedMessages,
        },
      }).then(() => {
        close();
      });
    }
  };

  const isPlanFree = doverPlan === ClientDoverPlanEnum.Free;
  const tooltipMessage = isPlanFree
    ? "Additional followups are not supported on the free plan."
    : `Outreach campaigns are limited to ${CAMPAIGN_MESSAGE_LIMIT} emails to avoid negative candidate interactions.`;

  return (
    <FormProvider {...formMethods}>
      <Stack spacing={2} p={2} mb={8}>
        {StatusBanner(campaign)}

        <Stack spacing={1}>
          <Subtitle1>Name</Subtitle1>
          <ControlledTextField
            name="name"
            placeholder="Campaign name"
            error={!!errors.name}
            helperText={errors.name?.message ?? ""}
          />
          <CampaignEmailSender campaign={campaign} />
        </Stack>

        <VariableSelector variables={CAMPAIGN_VARIABLE_KEYS} />
        <Stack spacing={2}>
          {(campaign.threadMessages ?? []).map((message, idx) => {
            return (
              <Stack spacing={2}>
                {idx === 0 && <Subtitle1>Initial outreach</Subtitle1>}
                <CampaignMessageEditor
                  key={`${campaign.id!}.${idx}`}
                  idx={idx}
                  initialBodyHtmlTemplate={message.bodyTemplate ?? ""}
                  initialSubjectHtmlTemplate={message.subjectTemplate ?? ""}
                  senderUser={senderUser}
                />
              </Stack>
            );
          })}
        </Stack>
        <Tooltip title={messageLimitReached ? tooltipMessage : ""} placement="top">
          <Stack width="100%" justifyContent="center" alignItems="center">
            <Button
              variant={ButtonVariant.Secondary}
              onClick={handleAddNewMessage}
              disabled={messageLimitReached}
              width="fit-content"
            >
              {"+ New Followup"}
            </Button>
          </Stack>
        </Tooltip>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        width="inherit"
        position="fixed"
        bottom={0}
        p={1}
        sx={{
          zIndex: 50,
          backgroundColor: colors.white,
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        {showDeleteButton && <DeleteCampaignButton campaign={campaign} onClose={close} />}
        <Stack direction="row" width="100%" justifyContent="flex-end">
          <Button
            disabled={isUpdatingCampaign || !!errorMessage}
            variant={ButtonVariant.Primary}
            onClick={handleSubmit(handleUpdateCampaign)}
            tooltip={errorMessage}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export default EditCampaignWrapper;
