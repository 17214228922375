import { Stack } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

import ProUserSelector from "components/dover/inputs/pro-users/ProUserDropdownSelector";
import { Checkbox } from "components/library/Checkbox";
import { CandidateFilterSourcingContextEnum } from "services/openapi";
import { StyledFormControlLabel } from "views/candidates/CandidateTable/filters/styles";
import { useParams } from "views/candidates/hooks";

interface CandidateSourceFilterProps {
  label: string;
  value: CandidateFilterSourcingContextEnum;
}

export const CandidateSourceFilter = ({ label, value }: CandidateSourceFilterProps): React.ReactElement => {
  const [{ sourcingContext, sourcer }, setParams] = useParams();

  const [sourcerId, setSourcerId] = useState<number | null>(sourcer ?? null);
  const setFromContextArray = useMemo(() => new Set(sourcingContext), [sourcingContext]);

  const handleClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setFromContextArray.add(value);
      } else {
        setFromContextArray.delete(value);
      }
    },
    [setFromContextArray, value]
  );

  const updateParams = useCallback(
    (sourcerId: number | null): void => {
      setParams({
        sourcingContext: Array.from(setFromContextArray),
        quickFilter: undefined,
        sourcer: sourcerId ?? undefined,
      });
    },
    [setFromContextArray, setParams]
  );

  const isChecked = useMemo(() => setFromContextArray.has(value), [setFromContextArray, value]);

  return (
    <Stack spacing={1}>
      <StyledFormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={(e): void => {
              handleClick(e);
              updateParams(sourcerId);
            }}
            value={value}
          />
        }
        label={label}
        labelPlacement={"end"}
      />
      {isChecked && value === CandidateFilterSourcingContextEnum.ManuallyAdded && (
        <ProUserSelector
          value={sourcerId}
          onChange={(value): void => {
            setSourcerId(value as number);
            updateParams(value as number);
          }}
          size="small"
          fullWidth={true}
          placeholderText={"Filter by sourcer"}
          hideAddOption
        />
      )}
    </Stack>
  );
};
