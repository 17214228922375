import { useHasFlag } from "components/RBAC";

export enum FeatureFlag {
  AISuggestedQuestions = "use_ai_suggested_interview_questions",
  DisableTitlesAutocomplete = "disable_titles_autocomplete",
  AINotetaker = "use_ai_notetaker",
  HubspotDoverEE = "hubspot_dover_ee",
  ExportCandidateCSV = "export_candidate_csv",
  AINotetakerUIConfig = "ai_notetaker_ui_config",
  DoverTalentNetwork = "dover_talent_network",
  RipSplashPages = "use_rip_splash_pages",
  SimpReview = "conditional_app_review_scoring",
  ShowNoReplyEmail = "show_no_reply_email",
  SimplifiedAddCandidate = "simplified_add_candidate",
  SourcingPage = "sourcing_page",
  SlugPoweredCareersPageUrls = "slug_powered_careers_page_urls",
  LegacySourcingAutopilot = "legacy_sourcing_autopilot",
  AdvertiseSourcingExtension = "advertise_sourcing_extension",
  Hotkeys = "hotkeys",
  Agencies = "agencies",
  ApiAccess = "use_external_api",
  DuplicateJob = "duplicate_job",
  ImprovedSchedulingUX = "use_improved_scheduling_ux",
  CareersPageSetup = "careers_page_setup",
  ApplicationReviewerAccess = "application_reviewer_access",
  MinimalCalAuth = "minimal_cal_auth",
}

export function useFeatureFlag(featureFlag: FeatureFlag): boolean {
  return useHasFlag(featureFlag);
}
