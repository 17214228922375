import { Progress } from "@doverhq/dover-ui";
import { Box, Stack, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Subtitle1 } from "components/library/typography";
import { usePrefetchFileUrl } from "services/fetchFilesApi";
import { ApplicationReview } from "services/openapi";
import { useListApplicationReviewsQuery } from "views/ApplicationsReviewer/endpoints";
import PaginatedSelectableList from "views/ApplicationsReviewer/PaginatedSelectableList";

const ITEMS_PER_PAGE = 10;

interface ApplicationBrowserProps {
  onItemSelected: (item: ApplicationReview, nextItem?: ApplicationReview) => void;
}

const ApplicationsBrowser = ({ onItemSelected }: ApplicationBrowserProps): React.ReactElement => {
  const [showReviewed, setShowReviewed] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<ApplicationReview | null>();
  const [page, setPage] = useState<number>(1);

  const prefetchFile = usePrefetchFileUrl("getFile");

  const { data, isLoading, isFetching } = useListApplicationReviewsQuery({
    limit: ITEMS_PER_PAGE,
    offset: (page - 1) * ITEMS_PER_PAGE,
    reviewed: showReviewed,
  });

  useEffect((): void => {
    if (selectedItem) onItemSelected(selectedItem);
  }, [selectedItem, onItemSelected]);

  useEffect((): void => {
    if (data?.results && data.results.length) {
      onItemSelected(data.results[0]);
      data.results.forEach(app => {
        if (app.resumeLink) prefetchFile(app.resumeLink);
      });
    }
  }, [data, onItemSelected, prefetchFile]);

  const table = (
    <PaginatedSelectableList
      totalPages={data ? Math.round(data.count / ITEMS_PER_PAGE) : 10}
      onPageSelect={(page: number): void => {
        setPage(page);
      }}
      onSelectItem={(item: ApplicationReview): void => {
        setSelectedItem(item);
      }}
      items={data?.results ?? []}
    />
  );

  return (
    <Stack sx={{ height: "100%" }}>
      <Tabs value={Number(showReviewed)} onChange={(): void => setShowReviewed(!showReviewed)}>
        <Tab label="To Do" id="todo-panel" />
        <Tab label="Reviewed" id="reviewed-panel" />
      </Tabs>
      <Box
        sx={{
          height: "calc( 100% - 48px );",
        }}
      >
        {(isLoading || isFetching) && (
          <Box height="100%" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Progress text="" size="large" />
          </Box>
        )}
        {!isLoading && !isFetching && !showReviewed && table}
        {!isLoading && !isFetching && showReviewed && table}
        {!isLoading && !isFetching && data?.results.length == 0 && (
          <Box height="100%" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Subtitle1>No data to display</Subtitle1>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default ApplicationsBrowser;
