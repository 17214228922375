import { Box, Stack } from "@mui/material";
import React from "react";

import { Card } from "components/library/Card";
import { Body, BodySmall, Overline } from "components/library/typography";
import { colors } from "styles/theme";

interface ApplicationAnswersTabProps {
  application?: {
    cleanFirstName?: string;
    additionalInfo?: string;
    applicationQuestions?: {
      question: string;
      answer?: string | null;
    }[];
  };
}

export const ApplicationAnswersTab: React.FC<React.PropsWithChildren<ApplicationAnswersTabProps>> = ({
  application,
}) => {
  const firstName = application?.cleanFirstName ?? "this applicant";
  const additionalInfo = application?.additionalInfo;
  const applicationQuestions = application?.applicationQuestions;

  if (!applicationQuestions?.length && !additionalInfo) {
    return (
      <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
        <Body weight="600">No application answers submitted</Body>
      </Stack>
    );
  }

  return (
    <Box m="1em">
      <Card>
        <Stack height="100%" spacing={1}>
          {additionalInfo && (
            <Stack spacing={0.75} bgcolor={colors.grayscale.gray100} p="1em" borderRadius="4px">
              <Overline>{`Message From ${firstName}`}</Overline>
              <Box sx={{ whiteSpace: "pre-wrap" }}>
                <BodySmall>{additionalInfo}</BodySmall>
              </Box>
            </Stack>
          )}
          {applicationQuestions?.map(question => (
            <div key={question.question}>
              <BodySmall weight="700">{question.question}</BodySmall>
              {question.answer ? (
                <BodySmall>{question.answer}</BodySmall>
              ) : (
                <BodySmall italic color={colors.grayscale.gray500}>
                  No response
                </BodySmall>
              )}
            </div>
          ))}
        </Stack>
      </Card>
    </Box>
  );
};
