/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InlineResponse20054
 */
export interface InlineResponse20054 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse20054
     */
    ready: boolean;
}

export function InlineResponse20054FromJSON(json: any): InlineResponse20054 {
    return InlineResponse20054FromJSONTyped(json, false);
}

export function InlineResponse20054FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse20054 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ready': json['ready'],
    };
}

export function InlineResponse20054ToJSON(value?: InlineResponse20054 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ready': value.ready,
    };
}


