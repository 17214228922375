/* -----------------------------------------------------------------------------
 * Drawer Header and Footer
 * -------------------------------------------------------------------------- */
import { Icon } from "@doverhq/dover-ui";
import { ReactComponent as XIcon } from "@doverhq/dover-ui/icons/x.svg";
import { Box, Stack } from "@mui/material";
import React from "react";

import { useDisableSave, useDisableSaveTooltip } from "components/dover/FeedbackTemplates/hooks";
import { Button, ButtonVariant } from "components/library/Button";
import { Tooltip } from "components/library/Tooltip";
import { Heading } from "components/library/typography";
import { colors } from "styles/theme";

interface DrawerHeaderProps {
  onClose: () => void;
}

export const DrawerHeader = ({ onClose }: DrawerHeaderProps): React.ReactElement => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        position: "sticky",
        top: 0,
        height: "56px",
        zIndex: 1400,
        borderBottom: `1px solid ${colors.grayscale.gray200}`,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" height="100%" px={3} py={1}>
        <Heading>Feedback form</Heading>
        <Box onClick={onClose} sx={{ cursor: "pointer" }}>
          <Icon Icon={XIcon} />
        </Box>
      </Stack>
    </Box>
  );
};

interface DrawerFooterProps extends DrawerHeaderProps {
  onSave: () => Promise<void>;
}

export const DrawerFooter = ({ onClose, onSave }: DrawerFooterProps): React.ReactElement => {
  const disableSave = useDisableSave();
  const disableSaveTooltip = useDisableSaveTooltip();

  return (
    <Box
      sx={{
        backgroundColor: "white",
        position: "sticky",
        bottom: 0,
        zIndex: 1400,
        borderTop: `1px solid ${colors.grayscale.gray200}`,
      }}
    >
      <Stack direction="row" spacing={1} justifyContent="flex-end" p={2} width="calc(100% - 100px)" margin="0 auto">
        <Button variant={ButtonVariant.Secondary} onClick={(): void => onClose()}>
          Discard changes
        </Button>
        <Tooltip title={disableSaveTooltip}>
          <div>
            <Button variant={ButtonVariant.Primary} onClick={onSave} disabled={!!disableSave}>
              Save feedback form
            </Button>
          </div>
        </Tooltip>
      </Stack>
    </Box>
  );
};
