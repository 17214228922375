import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import Tabs from "@mui/joy/Tabs";
import React, { useEffect, useState } from "react";

interface StyledTabProps {
  label: string;
  selected: boolean;
}

function StyledTab({ label, selected }: StyledTabProps): React.ReactElement {
  return (
    <Tab sx={{ fontWeight: "500px", color: selected ? "#3BA384" : "#667085" }} disableIndicator>
      {label}
    </Tab>
  );
}

interface TabItemProps {
  label: string;
  cb?: () => void;
}

interface ButtonTabsProps {
  tabs: TabItemProps[];
}

export default function ButtonTabs({ tabs }: ButtonTabsProps): React.ReactElement {
  const [i, setI] = useState<number>(0);

  useEffect(() => {
    const cb = tabs[i].cb;
    if (cb) cb();
  }, [i, tabs]);

  return (
    <Tabs
      value={i}
      onChange={(event, value): void => setI(value as number)}
      size="sm"
      aria-label="tabs"
      defaultValue={0}
      sx={{ bgcolor: "transparent" }}
    >
      <TabList
        disableUnderline
        sx={{
          p: 0.5,
          gap: 0.5,
          borderRadius: "xl",
          bgcolor: "background.level1",
          [`& .${tabClasses.root}[aria-selected="true"]`]: {
            boxShadow: "sm",
            bgcolor: "background.surface",
          },
        }}
      >
        {tabs.map((t, k) => (
          <StyledTab key={`styled-tab-${k}`} label={t.label} selected={k === i} />
        ))}
      </TabList>
    </Tabs>
  );
}
