import { atom } from "jotai";

import { Interviewer } from "components/dover/top-level-modal-manager/modals/candidate-action-modal/shared/InterviewerAutoComplete";
import { ClientEmailTemplate, HiringStageEmailTemplate } from "services/openapi";

// types
export enum EmailTemplateDrawerAction {
  Create = "create",
  Update = "update",
  Delete = "delete",
}

export interface EmailTemplateDrawerConfig {
  onCreateCallback?: (template: ClientEmailTemplate) => void;
  template?: HiringStageEmailTemplate | ClientEmailTemplate;
  interviewerSelectorProps?: {
    enabled?: boolean;
    defaultInterviewer?: Interviewer;
    jobId?: string;
  };
  action?: EmailTemplateDrawerAction;
}

// toggles
export const ToggleDeleteTemplateConfirmationModalAtom = atom<boolean>(false);
export const ToggleUnsavedChangesModalAtom = atom<boolean>(false);

// drawer state
export const EmailTemplateDrawerConfigAtom = atom<EmailTemplateDrawerConfig>({
  onCreateCallback: undefined,
  template: undefined,
  interviewerSelectorProps: undefined,
  action: undefined,
});
