import { Progress } from "@doverhq/dover-ui";
import { Box, Card, Stack, useTheme } from "@mui/material";
import React, { useState } from "react";

import { FileViewer } from "components/FileViewer";
import PageHelmet from "components/PageHelmet";
import { FeatureFlag, useFeatureFlag } from "hooks/useFeatureFlag";
import { ApplicationReview } from "services/openapi";
import ApplicationReviewForm from "views/ApplicationsReviewer/ApplicationReviewForm";
import ApplicationsBrowser from "views/ApplicationsReviewer/ApplicationsBrowser";
import NotFound from "views/NotFound";

const ApplicationsReviewer = (): React.ReactElement => {
  const [selectedItem, setSelectedItem] = useState<ApplicationReview | null>(null);
  const theme = useTheme();
  const hasFF = useFeatureFlag(FeatureFlag.ApplicationReviewerAccess);

  if (!hasFF) return <NotFound />;
  return (
    <>
      <PageHelmet title="Applications Reviewer" />

      <Stack direction="row" spacing={1} p={2} sx={{ height: "100vh" }}>
        <Stack spacing={2} sx={{ width: theme.spacing(40) }}>
          <Card sx={{ height: theme.spacing(48) }}>
            <Box sx={{ height: "100%" }}>
              {selectedItem ? (
                <ApplicationReviewForm data={selectedItem} />
              ) : (
                <Box height="100%" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Progress text="" size="large" />
                </Box>
              )}
            </Box>
          </Card>
          <Card sx={{ height: theme.spacing(52) }}>
            <Box sx={{ height: "100%" }}>
              <ApplicationsBrowser onItemSelected={setSelectedItem} />
            </Box>
          </Card>
        </Stack>
        <Box sx={{ flexGrow: 1 }}>
          <FileViewer url={selectedItem?.resumeLink ?? undefined} isPdf />
        </Box>
      </Stack>
    </>
  );
};

export default ApplicationsReviewer;
