import Stack from "@mui/material/Stack";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";

import FormRenderer from "components/data-driven-forms";
import { JOB_PITCH_MAPPER } from "components/data-driven-forms/component-mapper";
import CompanyPitchCard from "components/dover/JobPitch/CompanyPitchCard";
import { JobPitchProvider } from "components/dover/JobPitch/contexts";
import DrawerHeader from "components/dover/JobPitch/DrawerHeader";
import { useJobPitchContext } from "components/dover/JobPitch/hooks/useJobPitchContext";
import JobPitchFormTemplate from "components/dover/JobPitch/JobPitchFormTemplate";
import { Button, ButtonVariant } from "components/library/Button";
import Drawer from "components/library/Drawer";
import { Body, ButtonText, Subtitle2 } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import CustomModal from "components/Modal";
import useJobIdFromUrl from "hooks/useJobIdFromUrl";
import {
  useGetJobPitchQuestionSchemaQuery,
  useSaveJobPitchQuestionSchemaMutation,
} from "services/doverapi/endpoints/jobPitch";
import { colors } from "styles/theme";

//------------------------------------------------------------------------------
// Sub Components

const DrawerBody = (): React.ReactElement => {
  const { closeModal } = useJobPitchContext();
  const jobId = useJobIdFromUrl();
  const { data: jobPitchQuestionSchemaResponse, isLoading: loadingJobPitch } = useGetJobPitchQuestionSchemaQuery(
    jobId ?? skipToken
  );
  const [saveJobPitchQuestionSchema, { isLoading: updatingJobPitch }] = useSaveJobPitchQuestionSchemaMutation();

  return (
    <Stack
      direction="column"
      sx={{ p: 3, width: "100%", height: "100%", overflow: "auto" }}
      spacing={3}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <CompanyPitchCard fullWidth hideHelpText />
      {loadingJobPitch && !jobPitchQuestionSchemaResponse?.schema && <DoverLoadingSpinner active={true} />}
      {jobPitchQuestionSchemaResponse?.schema && (
        <FormRenderer
          schema={jobPitchQuestionSchemaResponse.schema}
          onSubmit={async (values: any): Promise<void> => {
            if (jobId) {
              await saveJobPitchQuestionSchema({ jobId, data: { answers: values } });
            }
          }}
          initialValues={jobPitchQuestionSchemaResponse?.answers}
          componentMapper={JOB_PITCH_MAPPER}
          // eslint-disable-next-line react/no-unstable-nested-components
          CustomFormTemplate={(props: any): React.ReactElement => (
            <JobPitchFormTemplate
              {...props}
              updating={loadingJobPitch || updatingJobPitch}
              afterSaveHook={(): void => {
                closeModal(true);
              }}
              fullWidth
            />
          )}
        />
      )}
    </Stack>
  );
};

const ConfirmDrawerCloseDialog = (): React.ReactElement => {
  const { discardChangesModalOpen, setDiscardChangesModalOpen, closeModal } = useJobPitchContext();
  const closeDiscardChangesModal = (): void => setDiscardChangesModalOpen(false);

  return (
    <CustomModal
      open={discardChangesModalOpen}
      title={<Subtitle2>Unsaved Changes</Subtitle2>}
      onClose={closeDiscardChangesModal}
      maxWidth="sm"
      dialogActions={
        <Stack direction="row" spacing={1}>
          <Button onClick={closeDiscardChangesModal} variant={ButtonVariant.Secondary}>
            <ButtonText>Continue Editing</ButtonText>
          </Button>
          <Button
            onClick={(): void => {
              closeDiscardChangesModal();
              closeModal(true);
            }}
            variant={ButtonVariant.Critical}
          >
            <ButtonText color={colors.white}>Discard Changes</ButtonText>
          </Button>
        </Stack>
      }
    >
      <Body>{`Closing will discard your unsaved changes. Review your job pitch responses and click 'Save' below`}</Body>
    </CustomModal>
  );
};

//------------------------------------------------------------------------------
// Main Component

const JobPitch = (): React.ReactElement => {
  const { closeModal, modalOpen } = useJobPitchContext();
  const onClose = (): void => closeModal(false);

  return (
    <>
      {/* Confirm discard changes modal */}
      <ConfirmDrawerCloseDialog />

      {/* Screener Question drawer */}
      <Drawer open={modalOpen} anchor="right" onClose={onClose} sx={{ position: "relative", overflow: "auto" }}>
        <DrawerHeader />
        <DrawerBody />
      </Drawer>
    </>
  );
};

export default (): React.ReactElement => {
  const context = useJobPitchContext();

  if (!context) {
    return <></>;
  }

  return (
    <JobPitchProvider>
      <JobPitch />
    </JobPitchProvider>
  );
};
