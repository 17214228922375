import { SerializedError } from "@reduxjs/toolkit";

import { doverApi } from "services/doverapi/apiSlice";
import { showErrorToast } from "utils/showToast";

const googleStoreEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    getFile: build.query<Uint8Array, string>({
      queryFn: async url => {
        try {
          const response = await fetch(url);
          const obj = await response.blob();
          const buff = await new Response(obj).arrayBuffer();
          const data = new Uint8Array(buff);
          return { data };
        } catch (error) {
          showErrorToast("Failed to get file");
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
  }),
});

export const { useGetFileQuery, usePrefetch: usePrefetchFileUrl } = googleStoreEndpoints;
