/* tslint:disable */
/* eslint-disable */
/**
 * Dover Internal API
 * Internal utility routes
 *
 * The version of the OpenAPI document: v1
 * Contact: devops@dover.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApplicationReview
 */
export interface ApplicationReview {
    /**
     * 
     * @type {string}
     * @memberof ApplicationReview
     */
    readonly id?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationReview
     */
    readonly created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationReview
     */
    readonly modified?: Date;
    /**
     * Resume pre-signed url, usually expires in 7 days.
     * @type {string}
     * @memberof ApplicationReview
     */
    resumeLink?: string | null;
    /**
     * Resume link expiration date
     * @type {Date}
     * @memberof ApplicationReview
     */
    resumeLinkExpirationDate?: Date | null;
    /**
     * Date of the application
     * @type {Date}
     * @memberof ApplicationReview
     */
    applicationDate?: Date | null;
    /**
     * Automatically extracted total years of experience
     * @type {number}
     * @memberof ApplicationReview
     */
    computedTotalYoe?: number | null;
    /**
     * 1/2 Automatically extracted T1 Title
     * @type {string}
     * @memberof ApplicationReview
     */
    computedTitleRaw1?: string | null;
    /**
     * 2/2 Automatically extracted T1 Title
     * @type {string}
     * @memberof ApplicationReview
     */
    computedTitleRaw2?: string | null;
    /**
     * 1/2 Automatically extracted T2 Title
     * @type {string}
     * @memberof ApplicationReview
     */
    computedTitleT21?: string | null;
    /**
     * 2/2 Automatically extracted T2 Title
     * @type {string}
     * @memberof ApplicationReview
     */
    computedTitleT22?: string | null;
    /**
     * Years of Experience annotated by reviewer
     * @type {number}
     * @memberof ApplicationReview
     */
    annotatedTotalYoe?: number;
    /**
     * 1/2 T1 Title annotated by reviewer
     * @type {string}
     * @memberof ApplicationReview
     */
    annotatedTitleRaw1?: string | null;
    /**
     * 2/2 T1 Title annotated by reviewer
     * @type {string}
     * @memberof ApplicationReview
     */
    annotatedTitleRaw2?: string | null;
    /**
     * 1/2 T2 Title annotated by reviewer
     * @type {string}
     * @memberof ApplicationReview
     */
    annotatedTitleT21?: string | null;
    /**
     * 2/2 T2 Title annotated by reviewer
     * @type {string}
     * @memberof ApplicationReview
     */
    annotatedTitleT22?: string | null;
    /**
     * Location annotated by reviewer
     * @type {string}
     * @memberof ApplicationReview
     */
    annotatedLocation?: string | null;
    /**
     * Automatically extracted location
     * @type {string}
     * @memberof ApplicationReview
     */
    computedLocation?: string | null;
    /**
     * Indicates the application was reviewed
     * @type {boolean}
     * @memberof ApplicationReview
     */
    reviewed?: boolean;
    /**
     * Automatically extracted job category
     * @type {string}
     * @memberof ApplicationReview
     */
    computedJobCategory?: string | null;
    /**
     * Job category annotated by reviewer
     * @type {string}
     * @memberof ApplicationReview
     */
    annotatedJobCategory?: string | null;
    /**
     * Automatically extracted boolean indicating if resume presents the candidate's seniority
     * @type {boolean}
     * @memberof ApplicationReview
     */
    computedHasSeniority?: boolean | null;
    /**
     * Indicates if the resume presents the candidate's seniority (annotated by reviewer)
     * @type {boolean}
     * @memberof ApplicationReview
     */
    annotatedHasSeniority?: boolean | null;
    /**
     * Indicates if the application is valid
     * @type {boolean}
     * @memberof ApplicationReview
     */
    valid?: boolean;
    /**
     * Automatically extracted boolean indicating if resume presents target locations
     * @type {boolean}
     * @memberof ApplicationReview
     */
    computedHasTargetLocations?: boolean;
    /**
     * Indicates if resume presents target locations (annotated by reviewer)
     * @type {boolean}
     * @memberof ApplicationReview
     */
    annotatedHasTargetLocations?: boolean;
    /**
     * Related inbound application
     * @type {string}
     * @memberof ApplicationReview
     */
    application?: string | null;
    /**
     * User that reviewed this application
     * @type {string}
     * @memberof ApplicationReview
     */
    user?: string | null;
}

export function ApplicationReviewFromJSON(json: any): ApplicationReview {
    return ApplicationReviewFromJSONTyped(json, false);
}

export function ApplicationReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationReview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'resumeLink': !exists(json, 'resume_link') ? undefined : json['resume_link'],
        'resumeLinkExpirationDate': !exists(json, 'resume_link_expiration_date') ? undefined : (json['resume_link_expiration_date'] === null ? null : new Date(json['resume_link_expiration_date'])),
        'applicationDate': !exists(json, 'application_date') ? undefined : (json['application_date'] === null ? null : new Date(json['application_date'])),
        'computedTotalYoe': !exists(json, 'computed_total_yoe') ? undefined : json['computed_total_yoe'],
        'computedTitleRaw1': !exists(json, 'computed_title_raw_1') ? undefined : json['computed_title_raw_1'],
        'computedTitleRaw2': !exists(json, 'computed_title_raw_2') ? undefined : json['computed_title_raw_2'],
        'computedTitleT21': !exists(json, 'computed_title_t2_1') ? undefined : json['computed_title_t2_1'],
        'computedTitleT22': !exists(json, 'computed_title_t2_2') ? undefined : json['computed_title_t2_2'],
        'annotatedTotalYoe': !exists(json, 'annotated_total_yoe') ? undefined : json['annotated_total_yoe'],
        'annotatedTitleRaw1': !exists(json, 'annotated_title_raw_1') ? undefined : json['annotated_title_raw_1'],
        'annotatedTitleRaw2': !exists(json, 'annotated_title_raw_2') ? undefined : json['annotated_title_raw_2'],
        'annotatedTitleT21': !exists(json, 'annotated_title_t2_1') ? undefined : json['annotated_title_t2_1'],
        'annotatedTitleT22': !exists(json, 'annotated_title_t2_2') ? undefined : json['annotated_title_t2_2'],
        'annotatedLocation': !exists(json, 'annotated_location') ? undefined : json['annotated_location'],
        'computedLocation': !exists(json, 'computed_location') ? undefined : json['computed_location'],
        'reviewed': !exists(json, 'reviewed') ? undefined : json['reviewed'],
        'computedJobCategory': !exists(json, 'computed_job_category') ? undefined : json['computed_job_category'],
        'annotatedJobCategory': !exists(json, 'annotated_job_category') ? undefined : json['annotated_job_category'],
        'computedHasSeniority': !exists(json, 'computed_has_seniority') ? undefined : json['computed_has_seniority'],
        'annotatedHasSeniority': !exists(json, 'annotated_has_seniority') ? undefined : json['annotated_has_seniority'],
        'valid': !exists(json, 'valid') ? undefined : json['valid'],
        'computedHasTargetLocations': !exists(json, 'computed_has_target_locations') ? undefined : json['computed_has_target_locations'],
        'annotatedHasTargetLocations': !exists(json, 'annotated_has_target_locations') ? undefined : json['annotated_has_target_locations'],
        'application': !exists(json, 'application') ? undefined : json['application'],
        'user': !exists(json, 'user') ? undefined : json['user'],
    };
}

export function ApplicationReviewToJSON(value?: ApplicationReview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resume_link': value.resumeLink,
        'resume_link_expiration_date': value.resumeLinkExpirationDate === undefined ? undefined : (value.resumeLinkExpirationDate === null ? null : value.resumeLinkExpirationDate.toISOString()),
        'application_date': value.applicationDate === undefined ? undefined : (value.applicationDate === null ? null : value.applicationDate.toISOString()),
        'computed_total_yoe': value.computedTotalYoe,
        'computed_title_raw_1': value.computedTitleRaw1,
        'computed_title_raw_2': value.computedTitleRaw2,
        'computed_title_t2_1': value.computedTitleT21,
        'computed_title_t2_2': value.computedTitleT22,
        'annotated_total_yoe': value.annotatedTotalYoe,
        'annotated_title_raw_1': value.annotatedTitleRaw1,
        'annotated_title_raw_2': value.annotatedTitleRaw2,
        'annotated_title_t2_1': value.annotatedTitleT21,
        'annotated_title_t2_2': value.annotatedTitleT22,
        'annotated_location': value.annotatedLocation,
        'computed_location': value.computedLocation,
        'reviewed': value.reviewed,
        'computed_job_category': value.computedJobCategory,
        'annotated_job_category': value.annotatedJobCategory,
        'computed_has_seniority': value.computedHasSeniority,
        'annotated_has_seniority': value.annotatedHasSeniority,
        'valid': value.valid,
        'computed_has_target_locations': value.computedHasTargetLocations,
        'annotated_has_target_locations': value.annotatedHasTargetLocations,
        'application': value.application,
        'user': value.user,
    };
}


