import { Button } from "@doverhq/dover-ui";
import { Box, Stack, useTheme, useMediaQuery } from "@mui/material";
import { useAtom, useSetAtom } from "jotai";
import React from "react";
import { useQueryParam, BooleanParam } from "use-query-params";

import { INVITE_TEAM_QUERY_PARAM } from "App/components/Nav/constants";
import InviteNewUsersModal from "App/components/Nav/InviteNewUsersModal";
import { CompanySetupSectionType } from "App/routing/types";
import { ReactComponent as BlockSVG } from "assets/icons/cancel.svg";
import { ReactComponent as ClipboardIconSVG } from "assets/icons/clipboard.svg";
import { ReactComponent as EditPencilSVG } from "assets/icons/pencil-edit.svg";
import { ReactComponent as UnblockSVG } from "assets/icons/refresh.svg";
import { TextWithMaxWidth } from "components/library/Body/TextWithMaxWidth";
import { Tooltip } from "components/library/Tooltip";
import { Body } from "components/library/typography";
import { DoverLoadingSpinner } from "components/loading-overlay";
import { UserAvatarCoin } from "components/PersonaCoin";
import { Role, useContainsRole } from "components/RBAC";
import { useGetClientId } from "services/doverapi/endpoints/client/hooks";
import {
  useListRolesQuery,
  useUpdateDefaultClientRoleMutation,
  useUpdateUserClientRoleMutation,
  useListAuthenticatedUsersQuery,
  useSetUserBlockedFlagMutation,
} from "services/doverapi/endpoints/RBAC/endpoints";
import { AuthedUser } from "services/openapi";
import { useAuth0 } from "services/react-auth0-spa";
import { colors, screenSizesNumbers } from "styles/theme";
import SectionWrapper from "views/CompanySetup/components/SectionWrapper";
import { ToggleEditModalOpenAtom, UserBeingEditedAtom } from "views/CompanySetup/components/Users/atoms";
import { BlockModal } from "views/CompanySetup/components/Users/components/BlockModal";
import { EditUserModal } from "views/CompanySetup/components/Users/components/EditUserModal";
import { PermissionsSelector } from "views/CompanySetup/components/Users/components/PermissionsSelector";
import { companySectionConfiguration } from "views/CompanySetup/constants";
import { StyledTab } from "views/Homepage/styles";
import { copyReferralLink } from "views/referralsV2/utils";

enum UserType {
  Active = "Active",
  Deactivated = "Deactivated",
}
const HORIZONTAL_PADDING = "12px";

export const UsersSection = (): React.ReactElement => {
  // state
  // if userToBlock is not undefined, then the block modal will be open
  const clientId = useGetClientId();

  const [userToBlockInModal, setUserToBlockInModal] = React.useState<AuthedUser | undefined>(undefined);
  const [userTypeSelected, setUserTypeSelected] = React.useState<UserType>(UserType.Active);
  const [editModalOpen, toggleEditModalOpen] = useAtom(ToggleEditModalOpenAtom);
  const setUserBeingEdited = useSetAtom(UserBeingEditedAtom);
  const [inviteNewUsersModalOpen, setInviteNewUsersModalOpen] = useQueryParam(INVITE_TEAM_QUERY_PARAM, BooleanParam);
  const userSettingsConfig = companySectionConfiguration[CompanySetupSectionType.MEMBERS];

  // copy to clipboard state
  const [clicked, setClicked] = React.useState(false);

  // Auth & Perms
  const { user: currentUser } = useAuth0();
  const userHasPermissions = useContainsRole([Role.ADMIN, Role.CLIENT_ADMIN]);

  // RTK
  const [setUserBlockedFlag, { isLoading: isBlockingUser }] = useSetUserBlockedFlagMutation();
  const [updateDefaultClientRole, { isLoading: isDefaultRoleUpdating }] = useUpdateDefaultClientRoleMutation();
  const { data: clientRoles, isLoading: isRolesLoading, isFetching: isRolesFetching } = useListRolesQuery();
  const { data: proUsersForClient, isFetching: isProUsersLoading } = useListAuthenticatedUsersQuery();

  const [, { isLoading: isUpdatingUserRole }] = useUpdateUserClientRoleMutation();

  // Mobile friendliness
  const muiTheme = useTheme();
  const isMediumScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.laptopML));
  const isSmallScreen = useMediaQuery(muiTheme.breakpoints.down(screenSizesNumbers.laptop));

  // Callbacks
  const onModalClose = (): void => {
    setUserToBlockInModal(undefined);
  };

  const handleDefaultPermChange = (val: string | undefined): void => {
    if (val) {
      updateDefaultClientRole({
        id: val,
        data: { isDefault: true },
      });
    }
  };

  // derived state
  const maxWidthPerBox = isSmallScreen ? "150px" : isMediumScreen ? "170px" : "215px";
  const isMutatingUsers = isDefaultRoleUpdating || isUpdatingUserRole || isBlockingUser;

  const activeUsers = React.useMemo(() => {
    return proUsersForClient?.filter(user => user.id !== undefined).filter(user => !user.blocked);
  }, [proUsersForClient]);

  const deactivatedUsers = React.useMemo(() => {
    return proUsersForClient?.filter(user => user.id !== undefined).filter(user => user.blocked);
  }, [proUsersForClient]);

  const usersToShow = userTypeSelected === UserType.Active ? activeUsers : deactivatedUsers;

  if (isRolesLoading || isProUsersLoading) {
    return <DoverLoadingSpinner />;
  }

  return (
    <SectionWrapper config={userSettingsConfig}>
      <InviteNewUsersModal open={!!inviteNewUsersModalOpen} onClose={(): void => setInviteNewUsersModalOpen(false)} />
      <Stack spacing={1} marginTop="16px" width="100%">
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{
            borderRadius: "4px",
          }}
          height="50px"
        >
          <Button onPress={(): void => setInviteNewUsersModalOpen(true)} variant="primaryFilled">
            Invite new user
          </Button>
          <PermissionsSelector
            options={clientRoles ?? []}
            value={clientRoles?.find(option => option.isDefault)}
            isLoading={isRolesFetching}
            setValue={handleDefaultPermChange}
            disabled={!userHasPermissions || isMutatingUsers}
            showVerboseCopyText
            styles={{
              ".MuiOutlinedInput-root.MuiInputBase-root": {
                outline: "none",
                border: "none",
              },
              minWidth: "150px",
            }}
          />
        </Stack>
        <Stack direction="row">
          <StyledTab
            onClick={(): void => setUserTypeSelected(UserType.Active)}
            $isSelected={userTypeSelected === UserType.Active}
          >
            Active
            {activeUsers !== undefined && ` (${activeUsers.length})`}
          </StyledTab>
          <StyledTab
            onClick={(): void => setUserTypeSelected(UserType.Deactivated)}
            $isSelected={userTypeSelected === UserType.Deactivated}
          >
            Deactivated
            {deactivatedUsers !== undefined && ` (${deactivatedUsers.length})`}
          </StyledTab>
        </Stack>
        <Stack>
          <Stack
            spacing={1}
            width="100%"
            border={`1px solid ${colors.grayscale.gray200}`}
            borderBottom="0"
            borderRadius="5px 5px 0 0"
            sx={{
              backgroundColor: colors.grayscale.gray100,
              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.05)",
            }}
            padding={HORIZONTAL_PADDING}
          >
            <Stack direction="row" width="100%">
              <Box display="flex" flexGrow={1} flexShrink={1} flexBasis={userHasPermissions ? "32%" : "33.33%"}>
                <Body>Name</Body>
              </Box>
              <Box display="flex" flexGrow={1} flexShrink={1} flexBasis={userHasPermissions ? "31.33%" : "33.33%"}>
                <Body>Email</Body>
              </Box>
              <Box display="flex" flexGrow={1} flexShrink={1} flexBasis={userHasPermissions ? "34.33%" : "33.33%"}>
                <Body>Role</Body>
              </Box>
              <Box display="flex" flexGrow={1} flexShrink={1} flexBasis="5.01%">
                Actions
              </Box>
            </Stack>
          </Stack>
          <Stack
            spacing={2}
            sx={{
              backgroundColor: colors.white,
              border: `1px solid ${colors.grayscale.gray200}`,
              boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.05)",
            }}
            padding={HORIZONTAL_PADDING}
          >
            {editModalOpen && <EditUserModal />}
            {usersToShow?.map(user => {
              const userIsBlocked = !!user.blocked;
              const userRole = clientRoles?.find(option => option.name && user.roles?.includes(option.name));
              const reactivateOrDeactivateText = userIsBlocked ? "Reactivate" : "Deactivate";
              return (
                <Stack key={user.id!} direction="row" width="100%">
                  <Box
                    display="flex"
                    flexGrow={1}
                    flexShrink={1}
                    flexBasis={userHasPermissions ? "32.33%" : "33.33%"}
                    alignItems="center"
                  >
                    <Stack direction="row" spacing={1}>
                      <UserAvatarCoin user={user} />
                      <TextWithMaxWidth label={user.name || "N/A"} width={maxWidthPerBox} TextElement={Body} />
                    </Stack>
                  </Box>
                  <Box
                    display="flex"
                    flexGrow={1}
                    flexShrink={1}
                    flexBasis={userHasPermissions ? "32.33%" : "33.33%"}
                    alignItems="center"
                  >
                    <TextWithMaxWidth label={user.email ?? ""} width={maxWidthPerBox} TextElement={Body} />
                  </Box>
                  <Box
                    display="flex"
                    flexGrow={1}
                    flexShrink={1}
                    flexBasis={userHasPermissions ? "32.33%" : "33.33%"}
                    alignItems="center"
                  >
                    <TextWithMaxWidth
                      label={userRole?.cleanName ?? "Unknown"}
                      width={maxWidthPerBox}
                      TextElement={Body}
                    />
                  </Box>
                  {/* Admins can edit anyone's info, and users should be able to edit their own info */}
                  <Box display="flex" flexGrow={1} flexShrink={1} flexBasis="3.01%" alignItems="center">
                    <Stack direction="row" alignItems={"center"}>
                      {(userHasPermissions || currentUser?.email === user.email) && (
                        <Tooltip title="Edit details">
                          <Stack minWidth={"24px"}>
                            <Button
                              icon={{
                                Icon: EditPencilSVG,
                              }}
                              onPress={(): void => {
                                setUserBeingEdited(user);
                                toggleEditModalOpen(true);
                              }}
                              p={0}
                            />
                          </Stack>
                        </Tooltip>
                      )}

                      <Tooltip
                        title={
                          clicked
                            ? "Copied!"
                            : `Copy ${user.name}'s unique referral link (to share with referred candidates)`
                        }
                        placement="top"
                        onOpen={(): void => setClicked(false)}
                      >
                        <div>
                          <Button
                            icon={{
                              Icon: ClipboardIconSVG,
                              color: "white",
                            }}
                            onPress={(): void => {
                              setClicked(true);
                              copyReferralLink({
                                clientId,
                                name: user.name,
                                email: user.email,
                              });
                            }} // on press open the modal for this user
                          />
                        </div>
                      </Tooltip>

                      {userHasPermissions && (
                        <Tooltip
                          title={
                            user.name
                              ? `${reactivateOrDeactivateText} ${user.name}`
                              : `${reactivateOrDeactivateText} user`
                          }
                        >
                          <div>
                            <Button
                              isDisabled={isMutatingUsers}
                              icon={{
                                Icon: userIsBlocked ? UnblockSVG : BlockSVG,
                              }}
                              onPress={(): void => setUserToBlockInModal(user)} // on press open the modal for this user
                            />
                          </div>
                        </Tooltip>
                      )}
                    </Stack>
                  </Box>
                </Stack>
              );
            })}
            {usersToShow?.length === 0 && <Body>No users found</Body>}
          </Stack>
        </Stack>
      </Stack>
      <BlockModal
        user={userToBlockInModal}
        open={!!userToBlockInModal}
        setUserBlockedFlag={setUserBlockedFlag}
        onClose={onModalClose}
        isMutatingUsers={isMutatingUsers}
      />
    </SectionWrapper>
  );
};
