import { SerializedError } from "@reduxjs/toolkit";

import { getOpenApiClients } from "services/api";
import { doverApi } from "services/doverapi/apiSlice";
import {
  CANDIDATE_BIO,
  CANDIDATE_INTERVIEW_RUBRIC_RESPONSE,
  LIST_FEEDBACK_FORMS_FOR_INTERVIEW,
  PIPELINE_CANDIDATE,
} from "services/doverapi/endpointTagsConstants";
import {
  InterviewQuestionSuggestionsResponse,
  InterviewRubricResponse,
  FormatAiAnswerResponse,
  CandidateMultipartInterview,
  FormatAiAnswerRequestFormattingPromptTypeEnum,
  SlimInterviewRubricResponse,
} from "services/openapi";
import { showErrorToast, showPendingToast, showSuccessToast } from "utils/showToast";

export interface GetInterviewRubricResponseRequest {
  candidateId: string;
  interviewId: string;
  forceCreate?: boolean;
}

const CandidateInterviewEndpoints = doverApi.injectEndpoints({
  endpoints: build => ({
    getInterviewRubricResponse: build.query<InterviewRubricResponse, GetInterviewRubricResponseRequest>({
      queryFn: async ({ candidateId, interviewId, forceCreate }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const result = await client.getInterviewRubricResponse({
            candidateId,
            interviewId,
            forceCreate,
          });
          return { data: result };
        } catch (error) {
          // @ts-ignore
          if (error.status !== 403) {
            showErrorToast("Couldn't load the interview response. Please try refreshing the page.");
          }
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: (result, _error, _arg) =>
        result
          ? [
              { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: result.id },
              { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: result.candidate?.id },
            ]
          : [],
    }),
    getInterviewRubricResponseById: build.query<InterviewRubricResponse, string>({
      queryFn: async rubricId => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const result = await client.getInterviewRubricResponseById({ id: rubricId });
          return { data: result };
        } catch (error) {
          // @ts-ignore
          if (error.status !== 403) {
            showErrorToast("Couldn't load the interview response. Please try refreshing the page.");
          }
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: (result, _error, _arg) =>
        result
          ? [
              { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: result.id },
              { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: result.candidate?.id },
            ]
          : [],
    }),
    listFeedbackFormsForInterview: build.query<SlimInterviewRubricResponse[], { interviewId: string }>({
      queryFn: async ({ interviewId }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const result = await client.listFeedbackFormsForInterview({
            interviewId,
          });
          return { data: result };
        } catch (error) {
          // @ts-ignore
          if (error.status !== 403) {
            showErrorToast("Couldn't load the interview response. Please try refreshing the page.");
          }
          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
      providesTags: (result, error, arg) =>
        result ? [{ type: LIST_FEEDBACK_FORMS_FOR_INTERVIEW, id: arg.interviewId }] : [],
    }),
    getOrCreateMultipartInterviewSubstages: build.query<
      CandidateMultipartInterview,
      { candidateId: string; hiringPipelineStageId: string }
    >({
      queryFn: async ({ candidateId, hiringPipelineStageId }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const result = await client.getOrCreateMultipartInterviewSubstages({
            data: {
              hiringPipelineStageId,
              candidateId,
            },
          });
          return { data: result };
        } catch (error) {
          showErrorToast("Couldn't load the interview stages. Please try refreshing the page.");

          return {
            error: {
              serializedError: error as SerializedError,
            },
          };
        }
      },
    }),
    addInterviewQuestionsToRubric: build.mutation<
      InterviewRubricResponse,
      { interviewRubricResponseId: string; questions: string[] }
    >({
      queryFn: async ({ interviewRubricResponseId, questions }) => {
        const { apiApi: client } = await getOpenApiClients({});
        const result = await client.addQuestionsToInterviewRubricResponse({
          id: interviewRubricResponseId,
          data: { questions },
        });
        return { data: result };
      },
      invalidatesTags: result => (result ? [{ type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: result.id }] : []),
    }),
    generateInterviewQuestionsWithAI: build.mutation<
      InterviewQuestionSuggestionsResponse,
      { interviewRubricResponseId: string }
    >({
      queryFn: async ({ interviewRubricResponseId }) => {
        const { apiApi: client } = await getOpenApiClients({});
        const result = await client.generateInterviewQuestions({
          id: interviewRubricResponseId,
          data: {},
        });
        return { data: result };
      },
    }),
    autofillInterviewRubricResponseWithAI: build.mutation<
      InterviewRubricResponse,
      { interviewRubricResponseId: string; roughNotes: string; candidateId: string }
    >({
      queryFn: async ({ interviewRubricResponseId, roughNotes }) => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const result = await client.autoformatInterviewRubricResponse({
            id: interviewRubricResponseId,
            data: { roughNotes },
          });
          return { data: result };
        } catch (error) {
          showErrorToast("Couldn't format the notes. Please try again.");
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
      async onQueryStarted({ interviewRubricResponseId }, { dispatch, queryFulfilled }) {
        const initialDispatch = dispatch(
          // @ts-ignore
          doverApi.util.updateQueryData("getInterviewRubricResponseById", interviewRubricResponseId, draft => {
            if (!draft) return;
            // set isAutoFillLoading to true manually here because this is currently the best way to ensure that
            // rubric responses are properly updated on the first mount of the response UI's
            // @ts-ignore
            Object.assign(draft, { rubricResponses: { ...draft.rubricResponses, isAutoFillLoading: true } });
          })
        );
        try {
          const queryResult = await queryFulfilled;
          dispatch(
            // @ts-ignore
            doverApi.util.updateQueryData("getInterviewRubricResponseById", interviewRubricResponseId, _ => {
              return queryResult.data;
            })
          );
        } catch {
          initialDispatch.undo();
        }
      },
    }),
    formatIndividualAIAnswer: build.mutation<
      FormatAiAnswerResponse,
      {
        questionLabel: string;
        currentAnswer: string;
        helperText?: string;
        formattingPromptType: FormatAiAnswerRequestFormattingPromptTypeEnum;
        interviewRubricResponseId: string;
      }
    >({
      queryFn: async ({
        interviewRubricResponseId,
        questionLabel,
        currentAnswer,
        helperText,
        formattingPromptType,
      }) => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const result = await client.formatIndividualAIAnswer({
            id: interviewRubricResponseId,
            data: {
              answer: currentAnswer,
              questionLabel: questionLabel,
              helperText: helperText,
              formattingPromptType: formattingPromptType,
            },
          });
          return { data: result };
        } catch (error) {
          showErrorToast("Couldn't format the notes. Please try again.");
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
    }),
    submitInterviewRubricResponse: build.mutation<
      InterviewRubricResponse,
      {
        interviewRubricResponseId: string;
        rubricResponses: Object;
        compiledJobInterviewRubricSchema?: Object;
        feedbackTemplate?: string;
      }
    >({
      queryFn: async ({
        interviewRubricResponseId,
        rubricResponses,
        compiledJobInterviewRubricSchema,
        feedbackTemplate,
      }) => {
        const { apiApi: client } = await getOpenApiClients({});

        try {
          const result = await client.partialUpdateInterviewRubricResponse({
            id: interviewRubricResponseId,
            data: { rubricResponses, interviewState: 300, compiledJobInterviewRubricSchema, feedbackTemplate },
          });
          showSuccessToast("Thanks! Your feedback has been saved.");
          return { data: result };
        } catch (error) {
          showErrorToast("Couldn't submit your feedback. Please try again.");
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
      invalidatesTags: result => (result ? [{ type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: result.id }] : []),
    }),
    updateInterviewRubricResponse: build.mutation<
      InterviewRubricResponse,
      {
        interviewRubricResponseId: string;
        rubricResponses: Object;
        interviewState?: number;
        feedbackTemplate?: string;
      }
    >({
      queryFn: async ({ interviewRubricResponseId, rubricResponses, interviewState, feedbackTemplate }) => {
        const { apiApi: client } = await getOpenApiClients({});
        try {
          const result = await client.partialUpdateInterviewRubricResponse({
            id: interviewRubricResponseId,
            data: { rubricResponses, interviewState, feedbackTemplate },
          });
          return { data: result };
        } catch (error) {
          showErrorToast("Couldn't save the interview response. Please try again.");

          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
      invalidatesTags: (result, _error, { feedbackTemplate }) => {
        // When feedbackTemplate is updated, we need to refresh the IRR to get the updated compiled form schema
        return feedbackTemplate && result
          ? [
              { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: result.id },
              { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: result.candidate?.id },
              { type: CANDIDATE_INTERVIEW_RUBRIC_RESPONSE, id: result.interview?.id },
            ]
          : [];
      },
    }),
    withdrawCandidate: build.mutation<boolean, { candidateId: string; isNoShow?: boolean }>({
      queryFn: async ({ candidateId, isNoShow = false }) => {
        const { apiApi: client } = await getOpenApiClients({});
        showPendingToast("Withdrawing the candidate...");
        try {
          const resp = await client.withdrawCandidate({ candidateId, data: { isNoShow } });
          showSuccessToast("Candidate has been withdrawn.");
          return { data: resp.success };
        } catch (error) {
          showErrorToast("Couldn't withdraw the candidate. Please try again.");
          return {
            error: { serializedError: error as SerializedError },
          };
        }
      },
      invalidatesTags: (result, _, args) => {
        return result
          ? [
              { type: PIPELINE_CANDIDATE, id: args.candidateId },
              { type: CANDIDATE_BIO, id: args.candidateId },
            ]
          : [];
      },
    }),
    submitNoShowRescheduleCandidate: build.mutation<void, { candidateId: string }>({
      queryFn: async ({ candidateId }) => {
        const { apiApi: client } = await getOpenApiClients({});
        showPendingToast("Recording no-show...");

        try {
          const resp = await client.submitNoShowRescheduleCandidate({ candidateId });
          showSuccessToast("No-show has been recorded.");
          return { data: resp };
        } catch (error) {
          showErrorToast("Couldn't record the no-show. Please try again.");
          throw error;
        }
      },
      invalidatesTags: (_result, _error, args) => {
        return [
          { type: PIPELINE_CANDIDATE, id: args.candidateId },
          { type: CANDIDATE_BIO, id: args.candidateId },
        ];
      },
    }),
  }),
});

export const {
  useGetInterviewRubricResponseQuery,
  useGetInterviewRubricResponseByIdQuery,
  useLazyGetInterviewRubricResponseQuery,
  useListFeedbackFormsForInterviewQuery,
  useGetOrCreateMultipartInterviewSubstagesQuery,
  useUpdateInterviewRubricResponseMutation,
  useSubmitInterviewRubricResponseMutation,
  useAddInterviewQuestionsToRubricMutation,
  useGenerateInterviewQuestionsWithAIMutation,
  useFormatIndividualAIAnswerMutation,
  useWithdrawCandidateMutation,
  useSubmitNoShowRescheduleCandidateMutation,
  useAutofillInterviewRubricResponseWithAIMutation,
} = CandidateInterviewEndpoints;
